import React, { FC, ReactNode } from "react";
import { Box, Container } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import defaultTheme from "../styles/theme";
import useBreakpoint from "../hooks/useBreakpoint";

export enum BaseLayoutContainerSize {
  large = "984px",
  small = "480px",
}

const BaseLayout: FC<IBaseLayout> = ({
  children,
  containerSize,
  footer,
  navbar,
}) => {
  const { isMobile } = useBreakpoint();
  const classes = useStyles({
    maxWidth:
      containerSize ||
      (isMobile
        ? BaseLayoutContainerSize.small
        : BaseLayoutContainerSize.large),
  });

  return (
    <Box className={classes.root}>
      {navbar ? navbar : <Navbar />}
      <Box className={classes.content}>
        <Container className={`${classes.container} .print-container`}>
          {children}
        </Container>
      </Box>

      {footer ? footer : <Footer />}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    maxWidth: ({ maxWidth }: IUseStyles) => maxWidth,
    padding: 0,
  },
  content: {
    backgroundColor: defaultTheme.colours.baseWhite,
    flexGrow: 1,
    marginBottom: "24px",
    padding: "24px",

    [theme.breakpoints.down("lg")]: {
      padding: "24px 0 0 0",
    },
  },
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    width: "100vw",
  },
}));

interface IUseStyles {
  maxWidth?: string;
}

interface IBaseLayout {
  children: ReactNode;
  containerSize?: BaseLayoutContainerSize;
  footer?: ReactNode;
  navbar?: ReactNode;
}

export default BaseLayout;
