import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Button, { ButtonVariant } from "../../../components/Button";
import React, { FC, ReactNode } from "react";
import { ReactComponent as ArrowLeft } from "../../../assets/icons/arrow_left.svg";
import defaultTheme from "../../../styles/theme";

const useStyles = makeStyles((theme) => ({
  controls: {
    marginTop: "24px",
  },
  fullWidth: {
    width: "100%",
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  textButton: {},
}));

interface IActionRedirect {
  isDisabled?: boolean;
  isLoading?: boolean;
  isShowNextButton?: boolean;
  goToPreviousStep?: () => void;
  goToNextStep?: () => void;
  children?: ReactNode;
  primaryLabel?: string;
}

const ActionRedirect: FC<IActionRedirect> = ({
  isDisabled,
  isLoading,
  goToPreviousStep,
  goToNextStep,
  children,
  primaryLabel,
}) => {
  const classes = useStyles();

  return (
    <Box className={classes.controls}>
      <Box className={classes.row}>
        {goToPreviousStep && (
          <Button
            className={classes.textButton}
            label="Go back"
            leftIcon={
              <ArrowLeft
                fill={defaultTheme.colours.night200}
                height={"14px"}
                width={"14px"}
              />
            }
            name="backBtn"
            variant={ButtonVariant.textNoUnderline}
            onClick={goToPreviousStep}
          />
        )}
        {goToNextStep && (
          <Button
            className={!goToPreviousStep ? classes.fullWidth : undefined}
            disabled={isDisabled}
            isLoading={isLoading}
            label={primaryLabel}
            name="backBtn"
            variant={ButtonVariant.contained}
            onClick={goToNextStep}
          />
        )}
        {children}
      </Box>
    </Box>
  );
};

export default ActionRedirect;
