import React, { FC, useEffect } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import { IBasicStepProps } from "../types";
import ActionRedirect from "../components/ActionRedirect";
import { makeStyles } from "@mui/styles";
import defaultTheme from "../../../styles/theme";
import { BusinessCommuteOptions } from "./config";
import Selection from "../../../components/Selection";
import Text, { TextVariant } from "../../../components/Text";

const BusinessCommute: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  const selectionOptions = BusinessCommuteOptions?.map(
    ({ emoji, key, label, value }) => {
      const isActive = formik?.values?.businessCommute.has(value);
      return (
        <Selection
          key={key}
          className={classes.selectionItem}
          disabled={!isActive && formik?.values?.businessCommute?.size >= 3}
          isSelected={isActive}
          label={t(label, { ns: "onboarding" })}
          onChange={() => {
            if (isActive) {
              if (formik.values.businessCommute?.delete(value)) {
                formik.setFieldValue(
                  "businessCommute",
                  formik.values.businessCommute
                );
              }
            } else {
              formik.setFieldValue(
                "businessCommute",
                formik.values.businessCommute?.add(value)
              );
            }
          }}
          startAdornment={
            emoji ? <Text variant={TextVariant.medium}>{emoji}</Text> : null
          }
          value={value}
        />
      );
    }
  );

  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingBusinessCommute);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("BUSINESS_COMMUTE.SUBHEADING", { ns: "onboarding" })}
        title={t("BUSINESS_COMMUTE.HEADING", { ns: "onboarding" })}
      >
        {selectionOptions}
        <ActionRedirect
          goToPreviousStep={decrementStep}
          goToNextStep={() => {
            try {
              trackAmpEvent(AMP_EVENTS.onboardingBusinessCommute, {
                [AMP_PROPERTIES.selections]: formik.values.businessCommute,
              });
            } catch (err) {
              console.error(err);
            }
            incrementStep();
          }}
          primaryLabel={t("BUSINESS_COMMUTE.PRIMARY_CTA", {
            ns: "onboarding",
          })}
        />
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    marginRight: "0",
  },
  label: {
    color: defaultTheme.colours.ink400,
  },
  selectionItem: {
    marginBottom: "8px",
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "30% 0",
  },
}));

export default BusinessCommute;
