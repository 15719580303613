import {
  Box,
  Button as MuiButton,
  ButtonProps,
  CircularProgress,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { makeStyles } from "@mui/styles";

import React, { FC, ReactNode, useRef } from "react";

import Text, { TextVariant } from "../Text";

import useHover from "../../hooks/useHover";

import defaultTheme from "../../styles/theme";

export enum ButtonSize {
  small = "small",
  medium = "medium",
}

export enum ButtonVariant {
  contained = "contained",
  containedCritical = "containedCritical",
  containedDark = "containedDark",
  outlined = "outlined",
  outlinedCritical = "outlinedCritical",
  text = "text",
  textNoUnderline = "textNoUnderline",
  textStone = "textStone",
  tonal = "tonal",
}

const Button: FC<IButton> = ({
  className,
  disabled,
  isLoading,
  label,
  leftIcon,
  name,
  onClick,
  rightIcon,
  size = ButtonSize.medium,
  tabIndex,
  type = "button",
  variant = ButtonVariant.contained,
}) => {
  const anchorRef = useRef<null | HTMLElement>(null);
  const isHovered = useHover(anchorRef);
  const classes = useStyles({
    isLoading,
    state: getRenderState(disabled, isHovered, isLoading),
  });
  const isSmallButton = size === ButtonSize.small;

  const WrappedButton = styled(MuiButton)<ButtonProps>(({ theme }) => ({
    backgroundColor:
      paletteButton[variant]?.backgroundColor?.[
        getRenderState(disabled, isHovered, isLoading)
      ],
    border:
      paletteButton[variant]?.border[
        getRenderState(disabled, isHovered, isLoading)
      ],
    borderRadius: "4px",
    height: isSmallButton ? "40px" : "56px",
    padding: isSmallButton
      ? variant === ButtonVariant.text ||
        variant === ButtonVariant.textNoUnderline ||
        variant === ButtonVariant.textStone
        ? "8px"
        : "8px 16px"
      : variant === ButtonVariant.text ||
        variant === ButtonVariant.textNoUnderline ||
        variant === ButtonVariant.textStone
      ? "12px"
      : "12px 24px",
    textDecorationLine:
      variant === ButtonVariant.text || variant === ButtonVariant.textStone
        ? "underline"
        : "none",
    textTransform: "none",
    width: "fit-content",
    "&:hover": {
      backgroundColor: paletteButton[variant]?.backgroundColor?.hover,
      border: paletteButton[variant]?.border?.hover,
      color: paletteButton[variant]?.color?.hover,
      textDecorationLine:
        variant === ButtonVariant.text || variant === ButtonVariant.textStone
          ? "underline"
          : "none",
    },
  }));

  const WrappedLoader = styled(CircularProgress)<any>(({ theme }) => ({
    color: paletteButton[variant]?.color?.loading,
  }));

  return (
    <WrappedButton
      className={`${className}`}
      disabled={isLoading || disabled}
      onMouseDown={(e) => e.preventDefault()}
      onKeyDown={(e) => e.preventDefault()}
      name={name}
      onClick={onClick}
      tabIndex={tabIndex}
      type={type as any}
      style={{
        color: isLoading
          ? "transparent"
          : paletteButton[variant]?.color[
              getRenderState(disabled, isHovered, isLoading)
            ],
      }}
    >
      <Box className={classes.inner}>
        {leftIcon && <Box className={classes.leftIcon}>{leftIcon}</Box>}
        <Text isBold variant={TextVariant.medium}>
          {isLoading && (
            <Box className={classes.spinnerContainer}>
              <WrappedLoader size={size === ButtonSize.small ? 18 : 22} />
            </Box>
          )}
          {label}
        </Text>
        {rightIcon && <Box className={classes.rightIcon}>{rightIcon}</Box>}
      </Box>
    </WrappedButton>
  );
};

const useStyles = makeStyles((theme) => ({
  inner: { alignItems: "center", display: "flex" },
  leftIcon: {
    alignItems: "center",
    display: "flex",
    marginRight: "8px",
    padding: 0,
  },
  rightIcon: {
    alignItems: "center",
    display: "flex",
    marginLeft: "4px",
    padding: 0,
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    left: 0,
    position: "absolute",
    top: 0,
    width: "100%",
  },
}));

interface IButton {
  className?: string;
  disabled?: boolean;
  isLoading?: boolean;
  label: ReactNode;
  leftIcon?: false | JSX.Element;
  name: string;
  onClick?: (e?: any) => void;
  rightIcon?: JSX.Element;
  size?: ButtonSize;
  tabIndex?: number;
  type?: string;
  variant: ButtonVariant;
}

const getRenderState = (disabled, isHovered, isLoading) => {
  if (disabled) {
    return "disabled";
  } else if (isLoading) {
    return "loading";
  } else if (isHovered) {
    return "hover";
  }

  return "default";
};

const paletteButton = {
  [ButtonVariant.contained]: {
    backgroundColor: {
      default: defaultTheme.colours.night200,
      disabled: defaultTheme.colours.stone200,
      hover: defaultTheme.colours.night300,
      loading: defaultTheme.colours.night100,
    },
    border: {
      default: "none",
      disabled: "none",
      hover: "none",
      loading: "none",
    },
    color: {
      default: defaultTheme.colours.baseWhite,
      disabled: defaultTheme.colours.ink100,
      hover: defaultTheme.colours.baseWhite,
      loading: defaultTheme.colours.baseWhite,
      transparent: "transparent",
    },
  },
  [ButtonVariant.containedCritical]: {
    backgroundColor: {
      default: defaultTheme.colours.criticalIcon,
      disabled: defaultTheme.colours.stone200,
      hover: defaultTheme.colours.criticalIcon,
      loading: defaultTheme.colours.night100,
    },
    border: {
      default: "none",
      disabled: "none",
      hover: "none",
      loading: "none",
    },
    color: {
      default: defaultTheme.colours.baseWhite,
      disabled: defaultTheme.colours.ink100,
      hover: defaultTheme.colours.baseWhite,
      loading: defaultTheme.colours.baseWhite,
      transparent: "transparent",
    },
  },
  [ButtonVariant.containedDark]: {
    backgroundColor: {
      default: defaultTheme.colours.ink500,
      disabled: defaultTheme.colours.stone200,
      hover: defaultTheme.colours.ink400,
      loading: defaultTheme.colours.ink300,
    },
    border: {
      default: "none",
      disabled: "none",
      hover: "none",
      loading: "none",
    },
    color: {
      default: defaultTheme.colours.baseWhite,
      disabled: defaultTheme.colours.ink100,
      hover: defaultTheme.colours.baseWhite,
      loading: defaultTheme.colours.baseWhite,
      transparent: "transparent",
    },
  },
  [ButtonVariant.outlined]: {
    backgroundColor: {
      default: "transparent",
      disabled: "transparent",
      hover: "transparent",
      loading: "transparent",
    },
    border: {
      default: `1px solid ${defaultTheme.colours.day300}`,
      disabled: `1px solid ${defaultTheme.colours.stone500}`,
      hover: `1px solid ${defaultTheme.colours.day500}`,
      loading: `1px solid ${defaultTheme.colours.day200}`,
    },
    color: {
      default: defaultTheme.colours.night200,
      disabled: defaultTheme.colours.stone500,
      hover: defaultTheme.colours.night300,
      loading: defaultTheme.colours.night200,
    },
  },
  [ButtonVariant.outlinedCritical]: {
    backgroundColor: {
      default: "transparent",
      disabled: "transparent",
      hover: "transparent",
      loading: "transparent",
    },
    border: {
      default: `1px solid ${defaultTheme.colours.criticalBorder}`,
      disabled: `1px solid ${defaultTheme.colours.stone500}`,
      hover: `1px solid ${defaultTheme.colours.criticalText}`,
      loading: `1px solid ${defaultTheme.colours.criticalBorder}`,
    },
    color: {
      default: defaultTheme.colours.criticalIcon,
      disabled: defaultTheme.colours.stone500,
      hover: defaultTheme.colours.criticalHover,
      loading: defaultTheme.colours.criticalIcon,
    },
  },
  [ButtonVariant.text]: {
    backgroundColor: {
      default: "transparent",
      disabled: "transparent",
      hover: defaultTheme.colours.day050,
      loading: defaultTheme.colours.day050,
    },
    border: {
      default: "none",
      disabled: "none",
      hover: "none",
      loading: "none",
    },
    color: {
      default: defaultTheme.colours.night200,
      disabled: defaultTheme.colours.stone500,
      hover: defaultTheme.colours.night300,
      loading: defaultTheme.colours.night200,
    },
  },
  [ButtonVariant.textNoUnderline]: {
    backgroundColor: {
      default: "transparent",
      disabled: "transparent",
      hover: defaultTheme.colours.day050,
      loading: defaultTheme.colours.day050,
    },
    border: {
      default: "none",
      disabled: "none",
      hover: "none",
      loading: "none",
    },
    color: {
      default: defaultTheme.colours.night200,
      disabled: defaultTheme.colours.stone500,
      hover: defaultTheme.colours.night300,
      loading: defaultTheme.colours.night200,
    },
  },
  [ButtonVariant.textStone]: {
    backgroundColor: {
      default: "transparent",
      disabled: "transparent",
      hover: defaultTheme.colours.stone050,
      loading: defaultTheme.colours.stone050,
    },
    border: {
      default: "none",
      disabled: "none",
      hover: "none",
      loading: "none",
    },
    color: {
      default: defaultTheme.colours.ink200,
      disabled: defaultTheme.colours.stone500,
      hover: defaultTheme.colours.ink300,
      loading: defaultTheme.colours.ink200,
    },
  },
  [ButtonVariant.tonal]: {
    backgroundColor: {
      default: defaultTheme.colours.day100,
      disabled: defaultTheme.colours.stone200,
      hover: defaultTheme.colours.day200,
      loading: defaultTheme.colours.day050,
    },
    border: {
      default: "none",
      disabled: "none",
      hover: "none",
      loading: "none",
    },
    color: {
      default: defaultTheme.colours.night200,
      disabled: defaultTheme.colours.ink100,
      hover: defaultTheme.colours.night300,
      loading: defaultTheme.colours.night200,
    },
  },
};

export default Button;
