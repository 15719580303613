import React, { useEffect } from "react";
import { ReactComponent as Illustration } from "../../assets/illustrations/error.svg";
import { Box } from "@mui/material";
import IllustrationLayout from "src/screens/Onboarding/steps/illustrationLayout";
import { makeStyles } from "@mui/styles";
import defaultTheme from "src/styles/theme";
import { useTranslation } from "react-i18next";
import { useAnalytics } from "../../services/analytics";
import { AMP_EVENTS } from "../../services/types";

const CheckoutError = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { trackAmpEvent } = useAnalytics();

  useEffect(() => {
    try {
      trackAmpEvent(AMP_EVENTS.checkoutError);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={classes.container}>
      <IllustrationLayout
        imageAlt="illustration"
        imageHeightDesktop="246px"
        imageHeightMobile="186px"
        illustration={
          <Illustration style={{ height: "inherit", width: "inherit" }} />
        }
        title={t("CHECKOUT.ERROR.TITLE", { ns: "plans" })}
      >
        {
          <>
            Please try again or{" "}
            <a href="mailto:support@betterwithbenji.com?subject=Inquiry%20from%20Benji%20app%20—%20payment%20error&body=I'm experiencing an error in checkout.">
              contact support
            </a>{" "}
            if the issue persists.
          </>
        }
      </IllustrationLayout>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    height: "100%",
    paddingTop: defaultTheme.spacing[8],
    padding: "0 1rem",
    textAlign: "center",
    width: "100%",
  },
}));

export default CheckoutError;
