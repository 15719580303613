import React, { FC, useEffect } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";
import { FormControl } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import SliderDisplay from "../../../components/SliderDisplay";

import ActionRedirect from "../../../screens/Onboarding/components/ActionRedirect";
import Banner, { BannerVariant } from "../../../components/Banner";
import { IBasicStepProps } from "../types";

const HomeUsage: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingHomeUsage);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("HOME_USAGE.SUBHEADING", { ns: "onboarding" })}
        title={t("HOME_USAGE.HEADING", { ns: "onboarding" })}
      >
        <FormControl className={classes.slider}>
          <SliderDisplay
            testid="home"
            sliderValue={formik.values.homeUsage}
            onChangePercentage={(e: any, value: any) =>
              formik?.setFieldValue("homeUsage", parseInt(value || 1, 10))
            }
          />
        </FormControl>
        <Banner
          className={classes.hint}
          subtitle={
            <>
              Even in a two-bedroom apartment, using your second bedroom as a
              workspace isn’t sufficient to write off 50% of your home-related
              expenses.
              <br />
              <br />
              With this in mind, consider the size of your home (including
              hallways, bathrooms, kitchen, etc.) and estimate the portion you
              only use for work.
              <br />
              <br />
              You don’t have to be 100% accurate, but you need to be reasonable.
            </>
          }
          title={t("FEELING_UNSURE", { ns: "common" })}
          type={BannerVariant.warning}
        />
        <ActionRedirect
          goToPreviousStep={decrementStep}
          goToNextStep={() => {
            try {
              trackAmpEvent(AMP_EVENTS.onboardingHomeUsage, {
                [AMP_PROPERTIES.usage]: formik.values.homeUsage,
              });
            } catch (err) {
              console.error(err);
            }
            incrementStep();
          }}
          primaryLabel={t("HOME_USAGE.PRIMARY_CTA", {
            ns: "onboarding",
          })}
        />
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  hint: {
    marginBottom: "24px",
  },
  slider: {
    marginBottom: "16px",
  },
}));

export default HomeUsage;
