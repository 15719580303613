import React, { FC, useEffect } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";
import { FormGroup } from "@mui/material";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import Option from "../../../components/Option";
import ActionRedirect from "../../../screens/Onboarding/components/ActionRedirect";
import { IBasicStepProps } from "../types";
import { BusinessStartOptions } from "./config";

const BusinessStart: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  const options = BusinessStartOptions?.map((option, index) => {
    const isActive = formik?.values?.businessStart === option.value;
    return (
      <Option
        key={`option_${option.key}`}
        label={t(option?.label, { ns: "onboarding" })}
        onClick={() => {
          formik.setFieldValue("businessStart", option.value);
          try {
            trackAmpEvent(AMP_EVENTS.onboardingStartedBusiness, {
              [AMP_PROPERTIES.option]: formik.values.businessStart,
            });
          } catch (err) {
            console.error(err);
          }
          incrementStep();
        }}
        selected={isActive}
        value={option?.value}
      />
    );
  });
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingStartedBusiness);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("BUSINESS_START.SUBHEADING", { ns: "onboarding" })}
        title={t("BUSINESS_START.HEADING", { ns: "onboarding" })}
      >
        <FormGroup>{options}</FormGroup>
        <ActionRedirect goToPreviousStep={decrementStep} />
      </FormLayout>
    </animated.div>
  );
};

export default BusinessStart;
