import React from "react";
import { CARegion, CountryCode, Region, USRegion } from "../types/enums";

const CAABFlag = require("../assets/flags/CA/CA-AB.png");
const CABCFlag = require("../assets/flags/CA/CA-BC.png");
const CAMBFlag = require("../assets/flags/CA/CA-MB.png");
const CANBFlag = require("../assets/flags/CA/CA-NB.png");
const CANLFlag = require("../assets/flags/CA/CA-NL.png");
const CANSFlag = require("../assets/flags/CA/CA-NS.png");
const CAONFlag = require("../assets/flags/CA/CA-ON.png");
const CAPEFlag = require("../assets/flags/CA/CA-PE.png");
const CAQCFlag = require("../assets/flags/CA/CA-QC.png");
const CASKFlag = require("../assets/flags/CA/CA-SK.png");
const USAKFlag = require("../assets/flags/US/US-AK.png");
const USALFlag = require("../assets/flags/US/US-AL.png");
const USARFlag = require("../assets/flags/US/US-AR.png");
const USAZFlag = require("../assets/flags/US/US-AZ.png");
const USCAFlag = require("../assets/flags/US/US-CA.png");
const USCOFlag = require("../assets/flags/US/US-CO.png");
const USCTFlag = require("../assets/flags/US/US-CT.png");
const USDCFlag = require("../assets/flags/US/US-DC.png");
const USDEFlag = require("../assets/flags/US/US-DE.png");
const USFLFlag = require("../assets/flags/US/US-FL.png");
const USGAFlag = require("../assets/flags/US/US-GA.png");
const USHIFlag = require("../assets/flags/US/US-HI.png");
const USIAFlag = require("../assets/flags/US/US-IA.png");
const USIDFlag = require("../assets/flags/US/US-ID.png");
const USILFlag = require("../assets/flags/US/US-IL.png");
const USINFlag = require("../assets/flags/US/US-IN.png");
const USKSFlag = require("../assets/flags/US/US-KS.png");
const USKYFlag = require("../assets/flags/US/US-KY.png");
const USLAFlag = require("../assets/flags/US/US-LA.png");
const USMAFlag = require("../assets/flags/US/US-MA.png");
const USMDFlag = require("../assets/flags/US/US-MD.png");
const USMEFlag = require("../assets/flags/US/US-ME.png");
const USMIFlag = require("../assets/flags/US/US-MI.png");
const USMNFlag = require("../assets/flags/US/US-MN.png");
const USMOFlag = require("../assets/flags/US/US-MO.png");
const USMSFlag = require("../assets/flags/US/US-MS.png");
const USMTFlag = require("../assets/flags/US/US-MT.png");
const USNCFlag = require("../assets/flags/US/US-NC.png");
const USNDFlag = require("../assets/flags/US/US-ND.png");
const USNEFlag = require("../assets/flags/US/US-NE.png");
const USNHFlag = require("../assets/flags/US/US-NH.png");
const USNJFlag = require("../assets/flags/US/US-NJ.png");
const USNMFlag = require("../assets/flags/US/US-NM.png");
const USNVFlag = require("../assets/flags/US/US-NV.png");
const USNYFlag = require("../assets/flags/US/US-NY.png");
const USOHFlag = require("../assets/flags/US/US-OH.png");
const USOKFlag = require("../assets/flags/US/US-OK.png");
const USORFlag = require("../assets/flags/US/US-OR.png");
const USPAFlag = require("../assets/flags/US/US-PA.png");
const USRIFlag = require("../assets/flags/US/US-RI.png");
const USSCFlag = require("../assets/flags/US/US-SC.png");
const USSDFlag = require("../assets/flags/US/US-SD.png");
const USTNFlag = require("../assets/flags/US/US-TN.png");
const USTXFlag = require("../assets/flags/US/US-TX.png");
const USUTFlag = require("../assets/flags/US/US-UT.png");
const USVAFlag = require("../assets/flags/US/US-VA.png");
const USVTFlag = require("../assets/flags/US/US-VT.png");
const USWAFlag = require("../assets/flags/US/US-WA.png");
const USWIFlag = require("../assets/flags/US/US-WI.png");
const USWVFlag = require("../assets/flags/US/US-WV.png");
const USWYFlag = require("../assets/flags/US/US-WY.png");

const CARegionFlags = {
  [CARegion.AB]: CAABFlag,
  [CARegion.BC]: CABCFlag,
  [CARegion.MB]: CAMBFlag,
  [CARegion.NB]: CANBFlag,
  [CARegion.NL]: CANLFlag,
  [CARegion.NS]: CANSFlag,
  [CARegion.ON]: CAONFlag,
  [CARegion.PE]: CAPEFlag,
  [CARegion.QC]: CAQCFlag,
  [CARegion.SK]: CASKFlag,
};

const USRegionFlags = {
  [USRegion.AL]: USALFlag,
  [USRegion.AK]: USAKFlag,
  [USRegion.AR]: USARFlag,
  [USRegion.AZ]: USAZFlag,
  [USRegion.CA]: USCAFlag,
  [USRegion.CO]: USCOFlag,
  [USRegion.CT]: USCTFlag,
  [USRegion.DC]: USDCFlag,
  [USRegion.DE]: USDEFlag,
  [USRegion.FL]: USFLFlag,
  [USRegion.GA]: USGAFlag,
  [USRegion.HI]: USHIFlag,
  [USRegion.IA]: USIAFlag,
  [USRegion.ID]: USIDFlag,
  [USRegion.IL]: USILFlag,
  [USRegion.IN]: USINFlag,
  [USRegion.KS]: USKSFlag,
  [USRegion.KY]: USKYFlag,
  [USRegion.LA]: USLAFlag,
  [USRegion.MA]: USMAFlag,
  [USRegion.MD]: USMDFlag,
  [USRegion.ME]: USMEFlag,
  [USRegion.MI]: USMIFlag,
  [USRegion.MN]: USMNFlag,
  [USRegion.MO]: USMOFlag,
  [USRegion.MS]: USMSFlag,
  [USRegion.MT]: USMTFlag,
  [USRegion.NC]: USNCFlag,
  [USRegion.ND]: USNDFlag,
  [USRegion.NE]: USNEFlag,
  [USRegion.NH]: USNHFlag,
  [USRegion.NJ]: USNJFlag,
  [USRegion.NM]: USNMFlag,
  [USRegion.NV]: USNVFlag,
  [USRegion.NY]: USNYFlag,
  [USRegion.OH]: USOHFlag,
  [USRegion.OK]: USOKFlag,
  [USRegion.OR]: USORFlag,
  [USRegion.PA]: USPAFlag,
  [USRegion.RI]: USRIFlag,
  [USRegion.SC]: USSCFlag,
  [USRegion.SD]: USSDFlag,
  [USRegion.TN]: USTNFlag,
  [USRegion.TX]: USTXFlag,
  [USRegion.UT]: USUTFlag,
  [USRegion.VA]: USVAFlag,
  [USRegion.VT]: USVTFlag,
  [USRegion.WA]: USWAFlag,
  [USRegion.WI]: USWIFlag,
  [USRegion.WV]: USWVFlag,
  [USRegion.WY]: USWYFlag,
};

export const getRegionFlag = (
  countryCode: CountryCode,
  regionCode: Region
  // style?: StyleProp<ImageStyle>
) => {
  let flag;
  if (countryCode === CountryCode.CA) {
    flag = CARegionFlags[regionCode as CARegion];
  } else {
    flag = USRegionFlags[regionCode as USRegion];
  }

  return (
    <img
      alt={`flag--${countryCode}-${regionCode}`}
      src={flag}
      height={"24px"}
      width={"100%"}
    />
  );

  // return (
  //   <Image
  //     resizeMethod="scale"
  //     resizeMode="contain"
  //     style={style}
  //     source={flag}
  //   />
  // );
};
