import { useEffect } from "react";
import { useSpring, animated } from "react-spring";
import { easeQuadOut } from "d3-ease";

const screenHeight = window.innerHeight;

export const useSlideUpAnimation = () => {
  const [springProps, setSpringProps] = useSpring(() => ({
    from: { transform: `translateY(${screenHeight}px)` },
    to: { transform: `translateY(0px)` },
    config: {
      easing: easeQuadOut,
      duration: 500,
    },
  }));

  const slideDownAnimation = () => {
    setSpringProps({
      from: { transform: `translateY(${-screenHeight / 7}px)` },
      to: { transform: `translateY(0px)` },
      config: {
        easing: easeQuadOut,
        duration: 500,
      },
    });
  };

  const slideUpAnimation = () => {
    setSpringProps({
      from: { transform: `translateY(${screenHeight}px)` },
      to: { transform: `translateY(0px)` },
      config: {
        easing: easeQuadOut,
        duration: 500,
      },
    });
  };

  useEffect(() => {
    slideUpAnimation();
  }, []);

  return {
    animated,
    slideDownAnimation,
    slideUpAnimation,
    springProps,
  };
};
