import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import defaultTheme from "../styles/theme";

const OnboardingLayout: FC<IOnboardingLayout> = ({ children }) => {
  const classes = useStyles({});

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>{children}</Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    backgroundColor: defaultTheme.colours.baseWhite,
    padding: "48px 24px 96px 24px",
    gap: "24px",

    [theme.breakpoints.down("lg")]: {
      padding: "0 24px 48px 24px",
    },
    width: "480px",
  },
  root: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    width: "100%",
    flex: 1,
  },
}));

interface IOnboardingLayout {
  children: ReactNode;
  footer?: ReactNode;
  navbar?: ReactNode;
}

export default OnboardingLayout;
