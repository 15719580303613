import React, { FC, useEffect } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";
import { FormControl } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import SliderDisplay from "../../../components/SliderDisplay";

import ActionRedirect from "../../../screens/Onboarding/components/ActionRedirect";
import Banner, { BannerVariant } from "../../../components/Banner";
import { IBasicStepProps } from "../types";

const PhoneUsage: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingPhoneUsage);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("PHONE_USAGE.SUBHEADING", { ns: "onboarding" })}
        title={t("PHONE_USAGE.HEADING", { ns: "onboarding" })}
      >
        <FormControl className={classes.slider}>
          <SliderDisplay
            testid="phone"
            sliderValue={formik?.values?.phoneUsage}
            onChangePercentage={(e: any, value: any) =>
              formik?.setFieldValue("phoneUsage", parseInt(value, 10))
            }
          />
        </FormControl>
        <Banner
          className={classes.hint}
          subtitle={
            <>
              Unless you have a dedicated phone for your business, you’re likely
              not using it all of the time for work (i.e. not 100%).
              <br />
              <br />
              With this in mind, reflect on your usage (e.g. work emails,
              creating content, engaging on social, etc.) for the past few
              months and estimate how often you used your phone for work during
              the week.
              <br />
              <br />
              You don’t have to be 100% accurate, but you do need to be
              reasonable.
            </>
          }
          title={t("FEELING_UNSURE", { ns: "common" })}
          type={BannerVariant.warning}
        />
        <ActionRedirect
          goToPreviousStep={decrementStep}
          goToNextStep={() => {
            try {
              trackAmpEvent(AMP_EVENTS.onboardingPhoneUsage, {
                [AMP_PROPERTIES.usage]: formik?.values?.phoneUsage,
              });
            } catch (err) {
              console.error(err);
            }
            incrementStep();
          }}
          primaryLabel={t("PHONE_USAGE.PRIMARY_CTA", {
            ns: "onboarding",
          })}
        />
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  hint: {
    marginBottom: "24px",
  },
  slider: {
    marginBottom: "16px",
  },
}));

export default PhoneUsage;
