import makeStyles from "@mui/styles/makeStyles";
import React, { FC, ReactNode } from "react";

const Heading: FC<IHeading> = ({
  children,
  className,
  isBold = false,
  variant,
}) => {
  const classes = useStyles({
    fontSize: paletteHeading.fontSize[variant],
    fontWeight: paletteHeading.fontWeight[variant],
    isBold,
    lineHeight: paletteHeading.lineHeight[variant],
  });
  return <p className={`${classes.default} ${className}`}>{children}</p>;
};

const useStyles = makeStyles((theme) => ({
  default: {
    color: "inherit",
    fontFamily: ({ isBold }: IUseStyles) =>
      isBold ? "NHaasGroteskDSPro65Md" : "NHaasGroteskDSPro55Rg",
    fontSize: ({ fontSize }: IUseStyles) => fontSize,
    fontStyle: "normal",
    fontWeight: ({ fontWeight }: IUseStyles) => fontWeight,
    lineHeight: ({ lineHeight }: IUseStyles) => lineHeight,
    margin: 0,
  },
}));

interface IUseStyles {
  fontSize: string;
  fontWeight: number;
  isBold?: boolean;
  lineHeight: string;
}

interface IHeading {
  children: ReactNode;
  className?: string;
  isBold?: boolean;
  variant: HeadingVariant;
}

export enum HeadingVariant {
  large = "large",
  medium = "medium",
  small = "small",
  xlarge = "xlarge",
  xxlarge = "xxlarge",
}

const paletteHeading = {
  fontSize: {
    [HeadingVariant.large]: "28px",
    [HeadingVariant.medium]: "24px",
    [HeadingVariant.small]: "20px",
    [HeadingVariant.xlarge]: "32px",
    [HeadingVariant.xxlarge]: "44px",
  },
  fontWeight: {
    [HeadingVariant.large]: 400,
    [HeadingVariant.medium]: 400,
    [HeadingVariant.small]: 400,
    [HeadingVariant.xlarge]: 400,
    [HeadingVariant.xxlarge]: 400,
  },
  lineHeight: {
    [HeadingVariant.large]: "36px",
    [HeadingVariant.medium]: "32px",
    [HeadingVariant.small]: "28px",
    [HeadingVariant.xlarge]: "40px",
    [HeadingVariant.xxlarge]: "48px",
  },
};

export default Heading;
