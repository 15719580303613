import i18next from "i18next";
import { initReactI18next } from "react-i18next";
// import * as Sentry from '@sentry/react-native';

import * as config from "./config";

i18next.use(initReactI18next).init(
  {
    resources: config.resources,
    fallbackLng: config.fallback,
    ns: config.namespaces,
    defaultNS: config.defaultNamespace,
    react: {
      useSuspense: true,
    },
    debug: false, //process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
  },
  (error) => {
    if (error) {
      // Sentry.captureException(error);
    }
  }
);
i18next.languages = config.languages;

export default i18next;
