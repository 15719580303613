export enum AMP_EVENTS {
  checkoutCancel = "checkout cancel",
  checkoutError = "checkout error",
  onboardingBusinessAccounts = "onboarding::business accounts",
  onboardingBusinessActivities = "onboarding::business activities",
  onboardingBusinessCommute = "onboarding::business commute",
  onboardingBusinessMeal = "onboarding::business meal",
  onboardingBusinessSize = "onboarding::business size",
  onboardingBusinessStructure = "onboarding::business structure",
  onboardingBusinessTravel = "onboarding::business travel",
  onboardingCaptureEmail = "onboarding::capture email",
  onboardingConnectBank = "onboarding::connect bank",
  onboardingCogs = "onboarding::cost of goods sold",
  onboardingCreateAccount = "onboarding::create account",
  onboardingCurrentResidence = "onboarding::current residence",
  onboardingError = "onboarding::error",
  onboardingExpectedOutcomes = "onboarding::expected outcomes",
  onboardingHomeOfficeSqFt = "onboarding::home office sq ft",
  onboardingHomeUsage = "onboarding::home usage",
  onboardingImportingTransactions = "onboarding::importing transactions",
  onboardingIncomeSource = "onboarding::income source",
  onboardingOperatingSystem = "onboarding::operating system",
  onboardingPhoneUsage = "onboarding::phone usage",
  onboardingPushNotifications = "onboarding::push notifications",
  onboardingSalesTax = "onboarding::sales tax",
  onboardingStartedBusiness = "onboarding::started business",
  onboardingSuccess = "onboarding::success",
  onboardingSummaryOverview = "onboarding::summary overview",
  onboardingWorkspace = "onboarding::workspace",
  onboardingVehicleUsage = "onboarding::vehicle usage",
}

export enum AMP_PROPERTIES {
  accounts = "accounts",
  businessActivities = "business activities",
  fields = "fields",
  institution = "institution",
  isBusiness = "is business",
  option = "option",
  selections = "selections",
  source = "source",
  sqft = "sq ft",
  transactionId = "transaction id",
  type = "type",
  usage = "usage",
}

export enum BENJI_API_KEYS {
  BANK_CONNECTIONS = "BANK_CONNECTIONS",
  BANNERS = "BANNERS",
  BENJI_CATEGORIES = "BENJI_CATEGORIES",
  BUSINESSES = "BUSINESSES",
  COUNTRIES = "COUNTRIES",
  MAILCHIMP_USER_EXISTS = "MAILCHIMP_USER_EXISTS",
  PAYMENT_INIT = "PAYMENT_INIT",
  USER = "USER",
  USER_LAST_LOGIN = "USER_LAST_LOGIN",
  USER_SETTINGS = "USER_SETTINGS",
  PROFESSIONS = "PROFESSIONS",
  REGIONS = "REGIONS",
  TRANSACTION_COUNT = "TRANSACTION_COUNT",
}

export enum PLAID_API_KEYS {
  CREATE_LINK_TOKEN = "CREATE_LINK_TOKEN",
  PLAID_INSTITUTION = "PLAID_INSTITUTION",
}

export interface IFetchUserSettingsResponse {
  data: Array<{ key: string; value: string }>;
}

export interface IPostBankAccountArgs {
  accounts: Array<{
    id: string;
    is_business: boolean;
  }>;
  bank_item_id: string;
  business_id: string;
  access_token: string;
}

export interface IPostBankConnectionArgs {
  accounts: Array<{
    id: string;
    is_business: boolean;
  }>;
  institution_id: string;
  business_id: string;
  public_token: string;
}

export interface IPostCaptureEmailArgs {
  country: string;
  email: string;
  firstName?: string;
  lastName?: string;
  tagType: string;
}
export interface IPostPlaidLinkEvent {
  error_code: string;
  error_type: string;
  link_session_id: string;
  request_id: string;
  status: string;
  type: string;
}
export interface IPostSignUpArgs {
  business_commute: Array<string>;
  business_meal: boolean;
  business_size: Array<string>;
  business_start: string;
  business_travel: boolean;
  cogs: boolean;
  country_id: string;
  email: string;
  full_name: string;
  home_office_sq_ft: number;
  home_usage_percentage: number;
  income_sources: Array<string>;
  password: string;
  phone_usage: number;
  occupations: Array<string>;
  referral_code?: string;
  region_id: string;
  sales_tax: string;
  signup_code?: string;
  timezone: string;
  signup_source: string;
  workspace: string;
}

export interface IOccupation {
  emoji: string;
  id: string;
  name: string;
}

export interface IRegion {
  country_id: string;
  id: string;
  name: string;
  region_code: string;
}

export interface IUpdateUserArgs {
  fcm_token?: string;
  last_login?: boolean;
}

export interface Business {
  id: string;
  country: {
    id: string;
    name: string;
    country_code: string;
  };
  ownerId: string;
  type: string;
}

export interface BankAccount {
  bankInstitutionId: string;
  id: string;
  isBusiness: boolean;
  mask: string;
  name: string;
  plaidAccountId?: string;
  plaidAccountIdStr?: string;
}

export interface Connection {
  accounts: Array<BankAccount>;
  institution: BankInstitution;
}

export interface BankInstitution {
  accessToken: string;
  businessId: string;
  id: string;
  plaidInstitutionId: string;
  plaidItemId: string;
}

export interface Profile {
  id: string;
  email: string;
  firstName: string;
  lastName: string;

  country: {
    id: number;
    name: string;
    country_code: string;
  };

  profile: {
    id: string;
    language: string;
    cron_timezone: string;
    iana_timezone: string;
    fcm_token: string;
  };

  settings: {
    key: string;
    value: string;
  }[];
}
