import React, { ChangeEvent, FC, FocusEventHandler, useRef } from "react";
import { makeStyles } from "@mui/styles";
import { Box, IconButton, CircularProgress } from "@mui/material";
import Text, { TextVariant } from "../Text";
import useHover from "../../hooks/useHover";
import defaultTheme from "../../styles/theme";
import CustomSvgIcon from "../CustomSvgIcon";
import { Close as CloseIcon } from "@mui/icons-material";

export enum InputVariant {}

enum State {
  default = "default",
  disabled = "disabled",
  error = "error",
  focus = "focus",
  hover = "hover",
}

const getPadding = (hasStartAdornment, hasEndAdornment, state) => {
  if (hasEndAdornment && hasStartAdornment) {
    return state === State.focus
      ? "12px 39px 12px 43px"
      : "12px 40px 12px 44px";
  }
  if (hasEndAdornment) {
    return state === State.focus
      ? "12px 15px 12px 39px"
      : "12px 16px 12px 40px";
  }
  if (hasStartAdornment) {
    return state === State.focus
      ? "12px 39px 12px 15px"
      : "12px 40px 12px 16px";
  }

  return state === State.focus ? "12px 15px" : "12px 16px";
};

const Input: FC<IInput> = ({
  autoComplete,
  className,
  disabled,
  endAdornment,
  endAdornmentOnClick,
  errorText,
  helperText,
  id,
  inputClassName,
  isError,
  isLoading,
  label,
  name,
  onBlur,
  onChange,
  onClear,
  onClick,
  onFocus,
  placeholder,
  startAdornment,
  tabIndex,
  type = "text",
  value,
}) => {
  const anchorRef = useRef<null | HTMLElement>(null);
  const inputRef = useRef<any>(null);
  const isHovered = useHover(anchorRef);
  const state = getRenderState(disabled, isError, isHovered);
  const classes = useStyles({
    hasEndAdornment: !!startAdornment,
    hasStartAdornment: !!endAdornment,
    state,
  });

  const getEndAdornment = (end, isLoading) => {
    let endAdornment = end ? (
      <CustomSvgIcon
        className={`${classes.endAdornment} ${
          endAdornmentOnClick && classes.pointer
        }`}
        clickHandler={endAdornmentOnClick}
      >
        {end}
      </CustomSvgIcon>
    ) : (
      <></>
    );

    if (value && value?.length > 0 && onClear) {
      endAdornment = (
        <Box className={classes.iconBackground}>
          <IconButton
            className={classes.iconButton}
            onClick={() => {
              onClear();
              inputRef?.current?.focus();
            }}
          >
            <CloseIcon color="inherit" />
          </IconButton>
        </Box>
      );
    }

    if (isLoading) {
      endAdornment = (
        <CircularProgress
          className={classes.spinner}
          style={{ height: "24px", width: "24px" }}
        />
      );
    }

    return endAdornment;
  };

  const getStartAdornment = (start) => {
    let startAdornment = start ? (
      <CustomSvgIcon className={classes.startAdornment}>{start}</CustomSvgIcon>
    ) : (
      <></>
    );
    return startAdornment;
  };

  return (
    <Box className={`${classes.container} ${className}`} onClick={onClick}>
      {label && (
        <Text className={classes.label} isBold variant={TextVariant.medium}>
          {label}
        </Text>
      )}
      <Box className={classes.inputContainer} ref={anchorRef}>
        {getStartAdornment(startAdornment)}
        <input
          autoComplete={autoComplete}
          className={`${classes.input} ${inputClassName}`}
          disabled={disabled || isLoading}
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={onChange}
          onFocus={onFocus}
          placeholder={placeholder}
          ref={inputRef}
          type={type}
          tabIndex={tabIndex}
          value={value}
        />
        {getEndAdornment(endAdornment, isLoading)}
      </Box>
      {isError && errorText && (
        <Text className={classes.error} variant={TextVariant.small}>
          {errorText}
        </Text>
      )}
      {!isError && helperText && (
        <Text className={classes.helperText} variant={TextVariant.small}>
          {helperText}
        </Text>
      )}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "flex-start",
    display: "flex",
    flexDirection: "column",
    position: "relative",
    width: "100%",
  },
  error: {
    color: defaultTheme.colours.criticalIcon,
    marginTop: "4px",
  },
  helperText: {
    marginTop: "4px",
  },
  iconBackground: {
    backgroundColor: defaultTheme.colours.baseWhite,
    position: "absolute",
    right: "8px",
    top: "4px",
  },
  iconButton: {
    color: defaultTheme.colours.ink100,

    "&:hover": {
      color: defaultTheme.colours.ink200,
    },
  },
  input: {
    backgroundColor: ({ state }: IUseStyles) => palette[state]?.backgroundColor,
    border: ({ state }: IUseStyles) => palette[state]?.border,
    borderRadius: "4px",
    caretColor: defaultTheme.colours.night200,
    color: ({ state }: IUseStyles) => palette[state]?.color,
    fontFamily: "NHaasGroteskTXPro55Rg",
    fontSize: "16px",
    fontWeight: 400,
    height: "48px",
    padding: ({ hasEndAdornment, hasStartAdornment, state }: IUseStyles) =>
      getPadding(hasStartAdornment, hasEndAdornment, state),
    width: "100%",
  },
  inputContainer: {
    height: "48px",
    position: "relative",
    width: "100%",
  },
  endAdornment: {
    color: ({ state }: IUseStyles) => palette[state]?.endIcon,
    position: "absolute",
    right: "16px",
    top: "12px",

    "&:hover": {
      color: defaultTheme.colours.ink200,
    },
  },
  label: { marginBottom: "4px" },
  pointer: { cursor: "pointer" },
  spinner: {
    color: "inherit",
    position: "absolute",
    right: "16px",
    top: "12px",
  },
  startAdornment: {
    left: "16px",
    position: "absolute",
    top: "12px",
  },
}));

interface IUseStyles {
  hasEndAdornment: boolean;
  hasStartAdornment: boolean;
  state: State;
}

interface IInput {
  autoComplete?: string;
  className?: string;
  disabled?: boolean;
  endAdornment?: JSX.Element;
  endAdornmentOnClick?: () => void;
  errorText?: any;
  helperText?: any;
  id?: string;
  inputClassName?: string;
  isError?: boolean;
  isLoading?: boolean;
  label?: string | null;
  name: string;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onClear?: () => void;
  onClick?: any;
  onFocus?: any;
  placeholder?: string;
  startAdornment?: JSX.Element;
  tabIndex?: number;
  type?: string;
  value?: string;
}

const getRenderState = (disabled, isError, isHovered) => {
  if (disabled) {
    return State.disabled;
  } else if (isError) {
    return State.error;
  } else if (isHovered) {
    return State.hover;
  }

  return State.default;
};

const palette = {
  default: {
    backgroundColor: defaultTheme.colours.baseWhite,
    border: `1px solid ${defaultTheme.colours.stone200}`,
    color: defaultTheme.colours.ink300,
    endIcon: defaultTheme.colours.ink100,
  },
  disabled: {
    backgroundColor: defaultTheme.colours.stone050,
    border: `1px solid ${defaultTheme.colours.stone200}`,
    color: defaultTheme.colours.ink200,
    endIcon: defaultTheme.colours.ink100,
  },
  error: {
    backgroundColor: defaultTheme.colours.baseWhite,
    border: `1px solid ${defaultTheme.colours.criticalIcon}`,
    color: defaultTheme.colours.ink300,
    endIcon: defaultTheme.colours.ink100,
  },
  focus: {
    backgroundColor: defaultTheme.colours.baseWhite,
    border: `2px solid ${defaultTheme.colours.night100}`,
    color: defaultTheme.colours.ink400,
    endIcon: defaultTheme.colours.ink100,
  },
  hover: {
    backgroundColor: defaultTheme.colours.baseWhite,
    border: `1px solid ${defaultTheme.colours.stone400}`,
    color: defaultTheme.colours.ink400,
    endIcon: defaultTheme.colours.ink200,
  },
};

export default Input;
