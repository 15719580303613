import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Onboarding from "./screens/Onboarding";
import Download from "./screens/Download";
import Login from "src/screens/Unauthenticated/Login";
import Logout from "src/screens/Logout";
import { AuthRoute } from "./components/AuthRoute";
import Auth from "src/screens/Auth";
import CheckoutInit from "src/screens/Checkout/Init";
import CheckoutCancel from "src/screens/Checkout/Cancel";
import CheckoutSuccess from "src/screens/Checkout/Success";
import CheckoutError from "src/screens/Checkout/Error";

const Router = () => {
  return (
    <Routes>
      <Route path="/auth" element={<Auth />} />
      <Route path="/logout" element={<Logout />} />
      <Route path="/login" element={<Login />} />
      <Route path="/signup" element={<Onboarding />} />
      <Route
        path="/onboarding/bank-connection"
        element={
          <AuthRoute>
            <Onboarding />
          </AuthRoute>
        }
      />
      <Route path="/download" element={<Download />} />
      <Route path="/checkout/init" element={<CheckoutInit />} />
      <Route path="/checkout/cancel" element={<CheckoutCancel />} />
      <Route path="/checkout/error" element={<CheckoutError />} />
      <Route path="/checkout/success" element={<CheckoutSuccess />} />

      <Route path="*" element={<Navigate replace to="/signup" />} />
    </Routes>
  );
};

export default Router;
