import React, { FC } from "react";
import IllustrationLayout from "./illustrationLayout";
import { ReactComponent as Illustration } from "../../../assets/illustrations/welcome.svg";
import makeStyles from "@mui/styles/makeStyles";
import Button, { ButtonVariant } from "../../../components/Button";
import { IBasicStepProps } from "../types";
import defaultTheme from "../../../styles/theme";
import { Box } from "@mui/material";
import Text, { TextVariant } from "../../../components/Text";
import { Link } from "react-router-dom";
import Heading, { HeadingVariant } from "../../../components/Heading";
import useBreakpoint from "src/hooks/useBreakpoint";
import CategoryIcon from "../components/CategoryIcon";
import { ICategoryIconSize } from "src/types";

const checklist = [
  {
    icon: {
      category_type: "",
      id: "emoji1",
      dominant_emoji_colour: "#B9B9B9",
      emoji: "📋",
      key: "ICON_1",
      name: "",
    },
    title: "WELCOME.CHECKLIST.ITEM_1.TITLE",
    subtitle: "WELCOME.CHECKLIST.ITEM_1.SUBTITLE",
  },
  {
    icon: {
      category_type: "",
      id: "emoji1",
      dominant_emoji_colour: "#B9B9B9",
      emoji: "🏦",
      key: "ICON_2",
      name: "",
    },
    title: "WELCOME.CHECKLIST.ITEM_2.TITLE",
    subtitle: "WELCOME.CHECKLIST.ITEM_2.SUBTITLE",
  },
  {
    icon: {
      category_type: "",
      id: "emoji1",
      dominant_emoji_colour: "#B9B9B9",
      emoji: "🔍",
      key: "ICON_3",
      name: "",
    },
    title: "WELCOME.CHECKLIST.ITEM_3.TITLE",
    subtitle: "WELCOME.CHECKLIST.ITEM_3.SUBTITLE",
  },
];

const WelcomeStep: FC<IBasicStepProps> = ({
  animated,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { isMobile } = useBreakpoint();

  return (
    <animated.div style={springProps}>
      <IllustrationLayout
        imageAlt="illustration"
        imageHeightDesktop="297px"
        imageHeightMobile="225px"
        illustration={
          <Illustration style={{ height: "inherit", width: "inherit" }} />
        }
        subtitle={t("WELCOME.SUBHEADING", { ns: "onboarding" })}
        title={t("WELCOME.HEADING", { ns: "onboarding" })}
      >
        <Box style={{ height: "12px" }} />
        <Heading
          isBold
          variant={isMobile ? HeadingVariant.small : HeadingVariant.medium}
        >
          {t("WELCOME.ACCOMPLISH", { ns: "onboarding" })}
        </Heading>
        <Box style={{ height: "12px" }} />
        {checklist?.map((item, index) => (
          <Box
            className={classes.checklistItem}
            key={`checklist_item_${index}`}
          >
            <CategoryIcon
              category={item?.icon}
              size={ICategoryIconSize.large}
            />
            <Box className={classes.checklistItemText}>
              <Text
                className={classes.checklistItemTitle}
                isBold
                variant={TextVariant.medium}
              >
                {t(item.title, { ns: "onboarding" })}
              </Text>
              <Box style={{ height: "4px" }} />
              <Text
                className={classes.checklistItemSubtitle}
                variant={TextVariant.small}
              >
                {t(item.subtitle, { ns: "onboarding" })}
              </Text>
            </Box>
          </Box>
        ))}

        <Box style={{ height: "36px" }} />
        <Button
          className={classes.button}
          label={t("WELCOME.PRIMARY_CTA", { ns: "onboarding" })}
          name="cta"
          variant={ButtonVariant.contained}
          onClick={() => {
            incrementStep();
          }}
        />
        <Box
          style={{
            display: "flex",
            paddingBottom: defaultTheme.spacing[6],
            paddingTop: defaultTheme.spacing[6],
          }}
        >
          <Text
            color={defaultTheme.colours.ink200}
            variant={TextVariant.medium}
          >
            {t("WELCOME.EXISTING_ACCOUNT", {
              ns: "onboarding",
            })}
          </Text>
          <Box style={{ width: "4px" }} />
          <Link className={classes.noUnderline} to={"/login"}>
            <Text variant={TextVariant.medium}>
              {t("LOGIN", {
                ns: "common",
              })}
            </Text>
          </Link>
        </Box>
      </IllustrationLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  button: {
    width: "100%",
  },
  checklistItem: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    padding: "12px 0",
  },
  checklistItemText: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "12px",
    width: "100%",
  },
  checklistItemTitle: {
    color: defaultTheme.colours.ink400,
  },
  checklistItemSubtitle: {
    color: defaultTheme.colours.ink200,
  },
  fullField: {
    width: "100%",
  },
  noUnderline: {
    textDecoration: "none",
  },
  toggleContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
  },
  toggleLeftSide: {},
  toggleSubtitle: {
    color: defaultTheme.colours.ink100,
  },
}));

export default WelcomeStep;
