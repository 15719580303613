export enum CanadianProvinces {
  AB = "Alberta",
  BC = "British Columbia",
  MB = "Manitoba",
  NB = "New Brunswick",
  NL = "Newfoundland and Labrador",
  NS = "Nova Scotia",
  NT = "Northwest Territories",
  NU = "Nunavut",
  ON = "Ontario",
  PE = "Prince Edward Island",
  SK = "Saskatchewan",
  YT = "Yukon",
}

export interface ProfileBusinessActivity {
  id: string | number;
  name: string;
}

export enum ProfileBusinessSalesTax {
  annual = "annual",
  false = "false",
  month = "month",
  quarter = "quarter",
}

export enum ProfileBusinessSetup {
  federalCorporation = "federal-corporation",
  provincialCorporation = "provincial-corporation",
  soleProprietorship = "sole-proprietorship",
  none = "none",
}

export enum ProfileBusinessStart {
  year2022 = "2022",
  year2021 = "2021",
  pre2020 = "pre-2020",
}

export enum ProfileBusinessStatus {
  fullTime = "full-time",
  partTime = "part-time",
}

export enum ProfileKey {
  businessActvity = "businessActvity",
  businessSetup = "businessSetup",
  businessStart = "businessStart",
  businessStatus = "businessStatus",
  homePercentage = "homePercentage",
  phonePercentage = "phonePercentage",
  residence = "residence",
  salesTax = "salesTax",
  shouldTrackMeals = "shouldTrackMeals",
  shouldTrackTravel = "shouldTrackTravel",
}

export enum Country {
  CA = 1,
  US = 2,
}

export interface IBenjiCategory {
  category_type: string;
  id: string;
  dominant_emoji_colour: string;
  emoji: string;
  key: string;
  name: string;
}

export enum ICategoryIconSize {
  small = "small",
  large = "large",
}

/**
 * Deprecated below
 */

export interface IPlaidAccount {
  account_id: string;
  available_balance: number;
  created_at: string;
  current_balance: number;
  deleted_at: string;
  disabled: boolean;
  id: string;
  iso_currency_code: string;
  mask: string;
  name: string;
  official_name: string;
  subtype: string;
  type: string;
  unofficial_currency_code: string;
  update_at: string;
  PlaidItemID: string;
}

export interface IPlaidInstitution {
  country_codes: Array<string>;
  credentials: any;
  has_mfa: boolean;
  institution_id: string;
  mfa: any;
  name: string;
  oauth: boolean;
  productions: Array<string>;
  routing_numbers: Array<string>;
}

export interface IPlaidItem {
  access_token: string;
  accounts: Array<IPlaidAccount>;
  created_at: string;
  deleted_at: string;
  id: string;
  institution_id: string;
  item_id: string;
  metadata: string;
  status: string;
  update_at: string;
  user_id: number;
}
