import React from "react";
import Grid from "@mui/material/Grid";
import Slider from "@mui/material/Slider";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Skeleton, TextField } from "@mui/material";
import { InputAdornment } from "@mui/material";

const useStyles = makeStyles((theme) => ({
  textField: {
    backgroundColor: "#FFFFFF",
    height: "48px",
    width: "70px",
    margin: "4px 16px 4px 0px",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 8,
    },
    paddingRight: "1px",
  },
  adornmentStart: {
    margin: 0,
    padding: 0,
  },
}));

const SliderDisplay = ({
  className = "",
  sliderValue,
  testid = "",
  isLoading = false,
  onChangePercentage,
  ...rest
}) => {
  const classes = useStyles();

  return (
    <Box className={className}>
      {isLoading ? (
        <Skeleton animation="wave" style={{ height: "56px", width: "100%" }} />
      ) : (
        <Grid container alignItems="center">
          <Grid item>
            <TextField
              name={`slider_${testid}_input`}
              className={classes.textField}
              value={`${sliderValue}`}
              onKeyDown={(event) => {
                if (event.keyCode === 13) {
                  event.preventDefault();
                }
              }}
              onChange={(event) => {
                const { value } = event.target;
                if (value === "") {
                  onChangePercentage(event, 0);
                } else if (value >= 0 && value <= 100) {
                  onChangePercentage(event, parseInt(value, 10));
                }
              }}
              onBlur={(event) => {
                const { value } = event.target;
                if (value === 0) {
                  onChangePercentage(event, 1);
                }
              }}
              inputProps={{
                inputMode: "numeric",
                style: {
                  height: "48px",
                  padding: "0px",
                  fontSize: "16px",
                  textAlign: "right",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment
                    classes={{ root: classes.adornmentStart }}
                    position="end"
                  >
                    %
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          <Grid item xs>
            <Slider
              id={`slider_${testid}`}
              data-testid={`slider_${testid}`}
              value={parseInt(sliderValue || "0", 10)}
              onChange={onChangePercentage}
              {...rest}
            />
          </Grid>
        </Grid>
      )}
    </Box>
  );
};

export default SliderDisplay;
