import { ErrorCodes } from "../../types/errors";

export const getErrorMessage = (code: string) => {
  switch (code) {
    case ErrorCodes.AUTH_101:
      return "CREATE_ACCOUNT.ERROR_UNKNOWN";
    case ErrorCodes.AUTH_102:
      return "LOGIN_SCREEN.ERROR_BANNER";
    case ErrorCodes.AUTH_104:
      return "CREATE_ACCOUNT.ERROR_EMAIL_IN_USE";
    case ErrorCodes.BANK_101:
    case ErrorCodes.BANK_102:
    case ErrorCodes.BANK_103:
    case ErrorCodes.BANK_104:
    case ErrorCodes.BANK_105:
    case ErrorCodes.BANK_106:
    case ErrorCodes.BANK_107:
    case ErrorCodes.BANK_108:
    case ErrorCodes.BANK_109:
    case ErrorCodes.BANK_110:
    case ErrorCodes.BANK_111:
      return "BANK_ACCOUNT_SELECTION.ERROR";
    case ErrorCodes.TAXP_101:
      return "CREATE_ACCOUNT.ERROR_UNKNOWN";
    case ErrorCodes.TAXP_102:
      return "CREATE_ACCOUNT.ERROR_INCORRECT_VALUE";
    default:
      return "";
  }
};
