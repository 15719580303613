import React, { FC, useEffect } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";
import { Box, FormGroup } from "@mui/material";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import Option from "../../../components/Option";
import ActionRedirect from "../../../screens/Onboarding/components/ActionRedirect";
import { IBasicStepProps } from "../types";
import { OperatingSystemOptions } from "./config";
import defaultTheme from "src/styles/theme";
import BannerEmailCollect from "../../../components/Banner/EmailCollect";
import Text, { TextVariant } from "../../../components/Text";

const OperatingSystem: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  const [selectedOption, setSelectedOption] = React.useState<string | null>(
    null
  );

  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingOperatingSystem);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const options = OperatingSystemOptions?.map((option, index) => {
    const isActive = selectedOption === option.value;
    return (
      <Option
        key={`option_${option.key}`}
        label={t(option?.label, { ns: "onboarding" })}
        onClick={() => {
          setSelectedOption(option?.value);
          try {
            trackAmpEvent(AMP_EVENTS.onboardingOperatingSystem, {
              [AMP_PROPERTIES.option]: option.value,
            });
          } catch (err) {
            console.error(err);
          }

          if (option.key === "IOS") {
            incrementStep();
          }
        }}
        selected={isActive}
        startAdornment={
          option?.emoji ? (
            <Text variant={TextVariant.medium}>{option?.emoji}</Text>
          ) : null
        }
        value={option?.value}
      />
    );
  });

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("OPERATING_SYSTEM.SUBHEADING", { ns: "onboarding" })}
        title={t("OPERATING_SYSTEM.HEADING", { ns: "onboarding" })}
      >
        <FormGroup>{options}</FormGroup>
        <Box style={{ height: defaultTheme.spacing[6] }} />
        {!!selectedOption && selectedOption === "ANDROID" ? (
          <BannerEmailCollect
            ctaText={t("OPERATING_SYSTEM.HINT_CTA", { ns: "onboarding" })}
            formik={formik}
            placeholder={t("OPERATING_SYSTEM.HINT_PLACEHOLDER", {
              ns: "onboarding",
            })}
            subtitle={t("OPERATING_SYSTEM.HINT_SUBTITLE", { ns: "onboarding" })}
            tagType="android"
            title={t("OPERATING_SYSTEM.HINT_TITLE", { ns: "onboarding" })}
          />
        ) : null}
        <ActionRedirect goToPreviousStep={decrementStep} />
      </FormLayout>
    </animated.div>
  );
};

export default OperatingSystem;
