import { PlanType } from "src/types/enums";

export const TEN_PX_SIMPLIFICATION = 0.0625;

export const MAX_FILES = 3;

export const spacing = (factor) => `${TEN_PX_SIMPLIFICATION * factor}rem`;

export const isPlanType = (value: string): value is PlanType => {
  return Object.values<string>(PlanType).includes(value);
};
