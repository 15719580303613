const defaultTheme = {
  colours: {
    baseBlack: "#000000",
    baseWhite: "#FFFFFF",
    benjiBlue: "#084BD0",
    criticalHover: "rgba(250, 126, 117, 0.15)",
    criticalSurface: "#FFF0F0",
    criticalBorder: "#FA7E75",
    criticalIcon: "#CF2000",
    criticalText: "#670C00",
    day050: "#E9F4FF",
    day100: "#D3E8FE",
    day200: "#A7CDFD",
    day300: "#80B1FA",
    day400: "#5E96F7",
    day500: "#427EF2",
    dayLight: "#F2F7FF",
    highlightHover: "rgba(128, 177, 250, 0.15)",
    highlightSurface: "#E9F4FF",
    highlightBorder: "#80B1FA",
    highlightIcon: "#2C69EB",
    highlightText: "#052A8A",
    ink: "#09090A",
    ink100: "#757575",
    ink200: "#5E5E5E",
    ink250: "#595959",
    ink300: "#464646",
    ink400: "#2F2F2F",
    ink500: "#171717",
    inkLight: "#262628",
    inkLightest: "#616264",
    inkLighter: "#434446",
    night100: "#2C69EB",
    night200: "#1B56DE",
    night300: "#0E42C3",
    night400: "#052A8A",
    night500: "#01123F",
    stone050: "#F3F3F3",
    stone100: "#E8E8E8",
    stone200: "#D0D0D0",
    stone300: "#B9B9B9",
    stone400: "#A1A1A1",
    stone500: "#8A8A8A",
    successHover: "rgba(117, 250, 188, 0.15)",
    successSurface: "#E7F9F0",
    successBorder: "#75FABC",
    successIcon: "#008060",
    successText: "#015742",
    warningHover: "rgba(250, 222, 112, 0.15)",
    warningSurface: "#FFF5E2",
    warningBorder: "#FADE70",
    warningIcon: "#987000",
    warningText: "#403000",
  },
  primaryButton: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    padding: "12px 24px",
  },
  spacing: {
    1: "4px",
    2: "8px",
    3: "12px",
    4: "16px",
    5: "20px",
    6: "24px",
    7: "32px",
    8: "48px",
    9: "64px",
  },
  textButton: {
    fontSize: "16px",
    fontWeight: 600,
    lineHeight: "24px",
    padding: "12px",
  },
};

export default defaultTheme;
