import React, { FC, ReactNode } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { AppBar, Box } from "@mui/material";
import defaultTheme from "../../styles/theme";
import CustomSvgIcon from "../CustomSvgIcon";
import { ReactComponent as BenjiLogo } from "../../assets/b-logo-beta.svg";

const Navbar: FC<INavbar> = ({
  className,
  externalLink,
  firstChild,
  onClick,
  secondChild,
}) => {
  const classes = useStyles();

  return (
    <AppBar
      position="sticky"
      color="inherit"
      className={`${classes.container} ${className}`}
    >
      <Box className={classes.row}>
        {externalLink ? (
          <a href={externalLink}>
            <CustomSvgIcon className={classes.logo}>
              <BenjiLogo />
            </CustomSvgIcon>
          </a>
        ) : (
          <CustomSvgIcon className={classes.pointer} clickHandler={onClick}>
            <BenjiLogo />
          </CustomSvgIcon>
        )}
        {firstChild}
        {secondChild}
      </Box>
    </AppBar>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    backgroundColor: defaultTheme.colours.baseWhite,
    borderBottom: "1px solid #D0D0D0",
    boxShadow: "none",
    height: "64px",
    padding: "0 24px",
  },
  logo: {
    cursor: "pointer",
    display: "flex",
  },
  pointer: {
    cursor: "pointer",
    display: "flex",
  },
  row: {
    alignItems: "center",
    display: "flex",
    flexGrow: 1,
    justifyContent: "space-between",
    width: "100%",
  },
}));

interface INavbar {
  className?: string;
  externalLink?: string;
  firstChild?: ReactNode;
  onClick?: () => void;
  secondChild?: ReactNode;
}

export default Navbar;
