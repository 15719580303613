import React, { FC, ReactNode, useState } from "react";
import { makeStyles } from "@mui/styles";
import { Box, Button } from "@mui/material";
import useTimeout from "../../hooks/useTimeout";
import Text, { TextVariant } from "../Text";

import defaultTheme from "../../styles/theme";

export interface IUseStyles {
  hoverBackgroundColor: string;
  backgroundColor: string;
  buttonBorder: string;
  borderColor: string;
  color: string;
}

enum ActionOptions {
  primaryAction = "primaryAction",
  secondaryAction = "secondaryAction",
}

const Banner: FC<IBanner> = ({
  title,
  subtitle,
  type,
  handlePrimaryAction,
  handleSecondaryAction,
  primaryButtonText = "Show me",
  secondaryButtonText = "Dismiss",
  className,
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);
  const [animatedOption, setAnimatedOption] = useState<ActionOptions | "">("");
  const classes = useStyles({
    backgroundColor: paletteBanner.backgroundColor[type],
    hoverBackgroundColor: paletteBanner.hoverBackgroundColor[type],
    borderColor: paletteBanner.borderColor[type],
    buttonBorder: paletteBanner.buttonBorder[type],
    color: paletteBanner.color[type],
  });
  const animationCondition = !!animatedOption
    ? classes.animatedOut
    : classes.animatedIn;

  useTimeout(
    () => {
      animatedOption === ActionOptions.secondaryAction
        ? onSecondaryClickHandler()
        : onPrimaryClickHandler();
    },
    !!animatedOption ? 300 : null
  );

  const onPrimaryClickHandler = () => {
    handlePrimaryAction && handlePrimaryAction();
    setIsHidden(true);
  };

  const onSecondaryClickHandler = () => {
    !!handleSecondaryAction && handleSecondaryAction();
    setIsHidden(true);
  };

  return !isHidden ? (
    <Box className={`${classes.container} ${animationCondition} ${className}`}>
      <Box>
        {title && (
          <Text className={classes.title} isBold variant={TextVariant.small}>
            {title}
          </Text>
        )}
        <Text className={classes.subtitle} variant={TextVariant.small}>
          {subtitle}
        </Text>
      </Box>
      {(!!handlePrimaryAction || !!handleSecondaryAction) && (
        <Box className={classes.rowAction}>
          {!!handlePrimaryAction && (
            <Button
              className={classes.button}
              variant="outlined"
              onClick={() => setAnimatedOption(ActionOptions.primaryAction)}
            >
              {primaryButtonText}
            </Button>
          )}

          {!!handleSecondaryAction && (
            <Button
              className={classes.button}
              variant="text"
              onClick={() => setAnimatedOption(ActionOptions.secondaryAction)}
            >
              {secondaryButtonText}
            </Button>
          )}
        </Box>
      )}
    </Box>
  ) : null;
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "24px",
    borderRadius: "12px",
    maxWidth: "100%",
    color: ({ color }: IUseStyles) => color,
    backgroundColor: ({ backgroundColor }: IUseStyles) => backgroundColor,
  },
  rowAction: {
    display: "flex",
    alignItems: "center",
    marginLeft: "16px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: 0,
      width: "100%",
      marginTop: "24px",
      justifyContent: "flex-start",
    },
  },
  subtitle: {},
  title: {
    marginBottom: "4px",
  },
  button: {
    fontSize: "16px",
    fontWeight: 400,
    whiteSpace: "nowrap",
    color: ({ color }: IUseStyles) => color,
    borderColor: ({ buttonBorder }: IUseStyles) => buttonBorder,
    "&:hover": {
      backgroundColor: ({ hoverBackgroundColor }: IUseStyles) =>
        hoverBackgroundColor,
      borderColor: ({ buttonBorder }: IUseStyles) => buttonBorder,
    },
    "&:last-child": {
      marginLeft: "16px",
    },
  },
  "@keyframes fadeIn": {
    from: {
      opacity: 0,
    },
    to: {
      opacity: 1,
    },
  },
  "@keyframes fadeOut": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0,
    },
  },
  animatedIn: {
    animation: `$fadeIn 300ms ${theme.transitions.easing.easeInOut}`,
  },
  animatedOut: {
    animation: `$fadeOut 300ms ${theme.transitions.easing.easeInOut}`,
  },
}));

export enum BannerVariant {
  highlight = "highlight",
  success = "success",
  warning = "warning",
  error = "error",
}

interface IBanner {
  title?: string;
  subtitle: ReactNode;
  type: BannerVariant;
  handlePrimaryAction?: () => void;
  handleSecondaryAction?: () => void;
  primaryButtonText?: string;
  secondaryButtonText?: string;
  className?: string;
}

const paletteBanner = {
  color: {
    [BannerVariant.highlight]: defaultTheme.colours.highlightText,
    [BannerVariant.error]: defaultTheme.colours.criticalText,
    [BannerVariant.warning]: defaultTheme.colours.warningText,
    [BannerVariant.success]: defaultTheme.colours.successText,
  },
  borderColor: {
    [BannerVariant.highlight]: defaultTheme.colours.highlightBorder,
    [BannerVariant.error]: defaultTheme.colours.criticalBorder,
    [BannerVariant.warning]: defaultTheme.colours.warningBorder,
    [BannerVariant.success]: defaultTheme.colours.successBorder,
  },
  buttonBorder: {
    [BannerVariant.highlight]: defaultTheme.colours.highlightIcon,
    [BannerVariant.error]: defaultTheme.colours.criticalIcon,
    [BannerVariant.warning]: defaultTheme.colours.warningIcon,
    [BannerVariant.success]: defaultTheme.colours.successIcon,
  },
  hoverBackgroundColor: {
    [BannerVariant.highlight]: defaultTheme.colours.highlightHover,
    [BannerVariant.error]: defaultTheme.colours.criticalHover,
    [BannerVariant.warning]: defaultTheme.colours.warningHover,
    [BannerVariant.success]: defaultTheme.colours.successHover,
  },
  backgroundColor: {
    [BannerVariant.highlight]: defaultTheme.colours.highlightSurface,
    [BannerVariant.error]: defaultTheme.colours.criticalSurface,
    [BannerVariant.warning]: defaultTheme.colours.warningSurface,
    [BannerVariant.success]: defaultTheme.colours.successSurface,
  },
};

export default Banner;
