import React, { FC, ReactNode, useRef } from "react";
import { Box, Checkbox } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import defaultTheme from "../../styles/theme";

import Text, { TextVariant } from "../Text";

import useHover from "../../hooks/useHover";

const Selection: FC<ISelection> = ({
  className,
  disabled,
  isError,
  isSelected,
  label,
  onChange,
  startAdornment,
  subtitle,
  value,
}) => {
  const anchorRef = useRef<null | HTMLElement>(null);
  const isHovered = useHover(anchorRef);
  const internalVariant = getInternalVariant({
    disabled,
    isError,
    isHovered,
    isSelected,
  });
  const classes = useStyles({
    backgroundColor: paletteSelection.backgroundColor[internalVariant],
    borderColor: paletteSelection.borderColor[internalVariant],
    color: paletteSelection.color[internalVariant],
    disabled,
    iconColor: paletteSelection.iconColor[internalVariant],
    isSelected,
  });

  return (
    <Box
      className={`${classes.container} ${className} ${
        isHovered && !disabled && classes.containerHover
      }`}
      ref={anchorRef}
      onClick={onChange}
    >
      <Box className={classes.innerContainer}>
        {startAdornment && (
          <Box className={classes.startAdornment}>{startAdornment}</Box>
        )}
        {subtitle ? (
          <Box className={classes.subtitleContainer}>
            <Text isBold className={classes.label} variant={TextVariant.medium}>
              {label}
            </Text>
            <Box style={{ height: "4px" }} />
            <Text className={classes.subtitle} variant={TextVariant.small}>
              {subtitle}
            </Text>
          </Box>
        ) : (
          <Text className={classes.label} variant={TextVariant.medium}>
            {label}
          </Text>
        )}
      </Box>
      <Checkbox
        color="primary"
        className={classes.checkbox}
        checked={isSelected}
        onChange={onChange}
        value={value}
      />
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  checkbox: {
    padding: 0,
    transition: "150ms",
    "&.MuiCheckbox-colorPrimary": {
      color: ({ iconColor }: IUseStyles) => iconColor,
    },
    "&.Mui-checked": {
      color: ({ iconColor }: IUseStyles) => iconColor,
    },
  },
  container: {
    alignItems: "center",
    backgroundColor: ({ backgroundColor }: IUseStyles) => backgroundColor,
    borderColor: ({ borderColor }: IUseStyles) => borderColor,
    borderRadius: "4px",
    borderStyle: "solid",
    borderWidth: "1px",
    color: ({ color }: IUseStyles) => color,
    cursor: ({ disabled }: IUseStyles) => (disabled ? "none" : "pointer"),
    display: "flex",
    justifyContent: "space-between",
    minHeight: "56px",
    padding: "16px",
    transition: "none",
    width: "100%",
  },
  containerHover: {
    backgroundColor: paletteSelection.backgroundColor.hover,
    borderColor: paletteSelection.borderColor.hover,
    borderWidth: paletteSelection.borderWidth.hover,
    color: paletteSelection.color.hover,
  },
  icon: {
    alignItems: "center",
    color: ({ iconColor }: IUseStyles) => iconColor,
    display: "flex",
    transition: "150ms cubic-bezier(0.5, 1, 0.89, 1)",
  },
  iconHover: {
    color: paletteSelection.iconColor.hover,
  },
  iconStart: {
    marginRight: "8px",
  },
  innerContainer: {
    alignItems: "center",
    display: "flex",
  },
  label: {
    marginRight: "4px",
    whiteSpace: "nowrap",
  },
  startAdornment: {
    alignItems: "center",
    marginRight: "16px",
  },
  subtitle: {},
  subtitleContainer: {
    display: "flex",
    flexDirection: "column",
    width: "90%",
  },
}));

interface IUseStyles {
  backgroundColor: string;
  borderColor: string;
  color: string;
  disabled?: boolean;
  iconColor: string;
  isSelected?: boolean;
}

interface ISelection {
  children?: ReactNode;
  className?: string;
  disabled?: boolean;
  startAdornment?: ReactNode;
  isError?: boolean;
  isSelected: boolean;
  label: ReactNode;
  leftIcon?: JSX.Element;
  onChange: (e?: any) => void;
  subtitle?: ReactNode;
  value: ReactNode;
}

export enum SelectionVariant {
  default = "default",
  disabled = "disabled",
  error = "error",
  hover = "hover",
  selected = "selected",
}

const getInternalVariant = ({
  disabled,
  isError,
  isHovered,
  isSelected,
}: {
  disabled: boolean | undefined;
  isError: boolean | undefined;
  isHovered: boolean;
  isSelected: boolean;
}) => {
  if (isError) {
    return SelectionVariant.error;
  } else if (disabled) {
    return SelectionVariant.disabled;
  } else if (isSelected) {
    return SelectionVariant.selected;
  } else if (isHovered) {
    return SelectionVariant.hover;
  }

  return SelectionVariant.default;
};

const paletteSelection = {
  backgroundColor: {
    [SelectionVariant.default]: defaultTheme.colours.baseWhite,
    [SelectionVariant.disabled]: defaultTheme.colours.stone050,
    [SelectionVariant.error]: defaultTheme.colours.baseWhite,
    [SelectionVariant.selected]: defaultTheme.colours.day050,
    [SelectionVariant.hover]: defaultTheme.colours.baseWhite,
  },
  borderColor: {
    [SelectionVariant.default]: defaultTheme.colours.stone200,
    [SelectionVariant.disabled]: defaultTheme.colours.stone200,
    [SelectionVariant.error]: defaultTheme.colours.criticalBorder,
    [SelectionVariant.selected]: defaultTheme.colours.night100,
    [SelectionVariant.hover]: defaultTheme.colours.stone400,
  },
  borderWidth: {
    [SelectionVariant.default]: "1px",
    [SelectionVariant.disabled]: "1px",
    [SelectionVariant.error]: "1px",
    [SelectionVariant.selected]: "1px",
    [SelectionVariant.hover]: "1px",
  },
  color: {
    [SelectionVariant.default]: defaultTheme.colours.ink300,
    [SelectionVariant.disabled]: defaultTheme.colours.ink100,
    [SelectionVariant.error]: defaultTheme.colours.ink300,
    [SelectionVariant.selected]: defaultTheme.colours.night200,
    [SelectionVariant.hover]: defaultTheme.colours.ink400,
  },
  iconColor: {
    [SelectionVariant.default]: defaultTheme.colours.ink200,
    [SelectionVariant.disabled]: defaultTheme.colours.ink100,
    [SelectionVariant.error]: defaultTheme.colours.ink200,
    [SelectionVariant.selected]: defaultTheme.colours.night100,
    [SelectionVariant.hover]: defaultTheme.colours.ink300,
  },
};

export default Selection;
