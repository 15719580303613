import { benjiAuthBaseV1 } from "./base";
import * as Sentry from "@sentry/react";
import {
  IPostBankAccountArgs,
  IPostBankConnectionArgs,
  IPostCaptureEmailArgs,
  IPostSignUpArgs,
  IUpdateUserArgs,
} from "./types";

export const fetchBankItems = async (businessId: string) => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/business/${businessId}/bank/items`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchBenjiCategories = async (id?: number) => {
  try {
    const data = await benjiAuthBaseV1.get(
      `/categories${id ? `?country_id=${id}` : ""}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchBusinesses = async () => {
  try {
    const { data } = await benjiAuthBaseV1.get("/businesses");

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchCheckoutInit = async (args) => {
  try {
    const { data } = await benjiAuthBaseV1.post(`/payment/generate`, args);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchCountryList = async () => {
  try {
    const { data } = await benjiAuthBaseV1.get("/location/countries");

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchMailchimpUser = async (email: string) => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/analytics/mailchimp/check?email=${email}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchOccupationsList = async () => {
  try {
    const { data } = await benjiAuthBaseV1.get("/occupations");

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchRegionsByCountryId = async (id: number) => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/location/regions?country_id=${id}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchTransactions = async ({
  businessId,
  limit = 100,
  offset = 0,
  pending,
  raw,
  reviewed,
  taxYear,
}: {
  businessId: string;
  limit: number;
  offset: number;
  pending?: boolean;
  raw?: boolean;
  reviewed?: boolean;
  taxYear: number;
}) => {
  try {
    const { data } = await benjiAuthBaseV1.get(
      `business/${businessId}/transactions?taxYear=${taxYear}&limit=${limit}&offset=${offset}&pending=${pending}&raw=${raw}&reviewed=${reviewed}`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchUserProfile = async () => {
  try {
    const { data } = await benjiAuthBaseV1.get(`/user`);

    return data;
  } catch (err) {
    Sentry.captureException(err);
    return err;
  }
};

export const postBankAccount = async (args: IPostBankAccountArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post(
      `/business/${args.business_id}/bank/item/${args.bank_item_id}/account`,
      {
        accounts: args.accounts,
        access_token: args.access_token,
      }
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postBankConnection = async (args: IPostBankConnectionArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post(
      `/business/${args.business_id}/bank/item`,
      {
        accounts: args.accounts,
        institution_id: args.institution_id,
        public_token: args.public_token,
      }
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postCaptureEmail = async ({
  country,
  email,
  firstName,
  lastName,
  tagType,
}: IPostCaptureEmailArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post(
      "/analytics/capture/onboarding",
      {
        country,
        email,
        first_name: firstName,
        last_name: lastName,
        tag_type: tagType,
      }
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postCompleteOnboarding = async (id: string) => {
  try {
    const { data } = await benjiAuthBaseV1.post(
      `/user/${id}/onboarding/complete`
    );

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postSignUp = async (args: IPostSignUpArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.post("/user", args);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const postUserSetting = async (key: string, value: string) => {
  try {
    const { data } = await benjiAuthBaseV1.post(`/user/setting`, {
      key,
      value,
    });

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const putUpdateUser = async (id: string, args: IUpdateUserArgs) => {
  try {
    const { data } = await benjiAuthBaseV1.put(`/user/${id}`, args);

    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};

export const fetchPlaidLinkToken = async (
  countryId: number = 2,
  token: string = ""
) => {
  const countryCodes = [countryId === 2 ? "US" : "CA"];
  try {
    const { data } = await benjiAuthBaseV1.get(
      `/plaid/create-link-token?country_codes=${countryCodes}&token=${token}`
    );
    return data;
  } catch (err) {
    Sentry.captureException(err);
  }
};
