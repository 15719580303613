import React from "react";
import Slider from "@mui/material/Slider";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Skeleton } from "@mui/material";
import { useTranslation } from "react-i18next";
import Text, { TextVariant } from "../../Text";
import defaultTheme from "src/styles/theme";

const useStyles = makeStyles((theme) => ({
  adornmentStart: {
    margin: 0,
    padding: 0,
  },
  container: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    width: "100%",
  },
  labelRow: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
  },
  marker: {
    color: defaultTheme.colours.ink200,
  },
  subtitle: {
    color: defaultTheme.colours.ink200,
    marginBottom: defaultTheme.spacing[4],
  },
  textField: {
    backgroundColor: "#FFFFFF",
    height: "48px",
    width: "70px",
    margin: "4px 16px 4px 0px",
    "& .MuiOutlinedInput-adornedEnd": {
      paddingRight: 8,
    },
    paddingRight: "1px",
  },
  title: {
    color: defaultTheme.colours.ink500,
  },
}));

const SliderSqFt = ({
  className = "",
  step = 1,
  value = 0,
  testid = "",
  isLoading = false,
  onChangePercentage,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const getSimplifiedSliderSubtitle = (value: any) => {
    if (value <= 60) {
      return "";
    } else if (value <= 145) {
      return t("SLIDER_SQ_FT.SIZE_THRESHOLD_1", {
        ns: "common",
      });
    } else if (value <= 245) {
      return t("SLIDER_SQ_FT.SIZE_THRESHOLD_2", {
        ns: "common",
      });
    } else {
      return t("SLIDER_SQ_FT.SIZE_THRESHOLD_3", {
        ns: "common",
      });
    }
  };

  return (
    <Box className={className}>
      {isLoading ? (
        <Skeleton animation="wave" style={{ height: "56px", width: "100%" }} />
      ) : (
        <Box className={classes.container}>
          <Text className={classes.title} isBold variant={TextVariant.medium}>
            {t("SQ_FT", { ns: "common", value })}
          </Text>
          <Box style={{ height: "4px" }} />
          <Text className={classes.subtitle} variant={TextVariant.small}>
            {getSimplifiedSliderSubtitle(value)}
          </Text>
          <Slider
            size={"small"}
            max={300}
            getAriaValueText={() => `${value} sq ft`}
            id={`slider_${testid}`}
            data-testid={`slider_${testid}`}
            step={step}
            value={value}
            onChange={onChangePercentage}
          />
          <Box className={classes.labelRow}>
            <Text className={classes.marker} variant={TextVariant.small}>
              {t("SQ_FT", { ns: "common", value: 0 })}
            </Text>
            <Text className={classes.marker} variant={TextVariant.small}>
              {t("SQ_FT", { ns: "common", value: 300 })}
            </Text>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default SliderSqFt;
