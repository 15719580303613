import makeStyles from "@mui/styles/makeStyles";
import React, { FC, ReactNode } from "react";

const Text: FC<IText> = ({
  color,
  children,
  className,
  isBold = false,
  onClick,
  variant,
}) => {
  const classes = useStyles({
    color,
    fontSize: paletteText.fontSize[variant],
    fontWeight: paletteText.fontWeight[variant],
    isBold,
    lineHeight: paletteText.lineHeight[variant],
  });
  return (
    <div className={`${classes.default} ${className}`} onClick={onClick}>
      {children}
    </div>
  );
};

const useStyles = makeStyles((theme) => ({
  default: {
    color: ({ color }: IUseStyles) => color || "inherit",
    fontFamily: ({ isBold }: IUseStyles) =>
      isBold ? "NHaasGroteskTXPro65Md" : "NHaasGroteskTXPro55Rg",
    fontSize: ({ fontSize }: IUseStyles) => fontSize,
    fontStyle: "normal",
    fontWeight: ({ fontWeight }: IUseStyles) => fontWeight,
    lineHeight: ({ lineHeight }: IUseStyles) => lineHeight,
    margin: 0,
  },
}));

interface IUseStyles {
  color?: string;
  fontSize: string;
  fontWeight: number;
  isBold?: boolean;
  lineHeight: string;
}

interface IText {
  color?: string;
  children: ReactNode;
  className?: string;
  isBold?: boolean;
  onClick?: () => void;
  variant: TextVariant;
}

export enum TextVariant {
  large = "large",
  medium = "medium",
  small = "small",
}

const paletteText = {
  fontSize: {
    [TextVariant.large]: "18px",
    [TextVariant.medium]: "16px",
    [TextVariant.small]: "14px",
  },
  fontWeight: {
    [TextVariant.large]: 400,
    [TextVariant.medium]: 400,
    [TextVariant.small]: 400,
  },
  lineHeight: {
    [TextVariant.large]: "28px",
    [TextVariant.medium]: "24px",
    [TextVariant.small]: "20px",
  },
};

export default Text;
