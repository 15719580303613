import React from "react";
import MobileStepper from "@mui/material/MobileStepper";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
  progress: {
    width: "100%",
  },
}));

const ProgresBar = ({
  className,
  totalSteps,
  currentStep,
}: {
  className?: any;
  totalSteps: number;
  currentStep: number;
}) => {
  const { progress } = useStyles();

  return (
    <MobileStepper
      className={className}
      variant="progress"
      steps={totalSteps}
      sx={{ padding: 0 }}
      position="static"
      activeStep={currentStep}
      classes={{ progress }}
      backButton={null}
      nextButton={null}
    />
  );
};

export default ProgresBar;
