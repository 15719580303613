import create from "zustand";

export interface OnboardingStore {
  accountMap: Array<any>;
  businessAccountIdList: Array<string>;
  currentStep: number;
  isLoading: boolean;
  linkSessionId: string;
  reset: () => void;
  setAccountMap: (accountMap: Array<any>) => void;
  setBusinessAccountIdList: (list: Array<string>) => void;
  setCurrentStep: (currentStep: number) => void;
  setIsLoading: (isLoading: boolean) => void;
  setLinkSessionId: (linkSessionId: string) => void;
}

const initStore = {
  accountMap: [],
  businessAccountIdList: [],
  currentStep: 0,
  isLoading: false,
  linkSessionId: "",
};

export const useOnboardingStore = create<OnboardingStore>((set) => ({
  ...initStore,
  reset: () => set(() => ({ ...initStore })),
  setAccountMap: (accountMap: Array<any>) => set(() => ({ accountMap })),
  setBusinessAccountIdList: (list: Array<string>) =>
    set({ businessAccountIdList: list }),
  setCurrentStep: (currentStep: number) => set(() => ({ currentStep })),
  setIsLoading: (isLoading: boolean) => set(() => ({ isLoading })),
  setLinkSessionId: (linkSessionId: string) => set(() => ({ linkSessionId })),
}));
