import auth0 from "auth0-js";
import { useEffect } from "react";
import { env } from "../utils/env";
import { useAuthStore } from "../stores/auth";
import { ErrorCodes } from "src/types/errors";

export const useAuth = () => {
  const {
    isAuthenticated,
    isBearerSet,
    token,
    setErrorCode,
    setIsAuthenticated,
    setIsBearerSet,
    setToken,
  } = useAuthStore((state) => ({
    isAuthenticated: state.isAuthenticated,
    isBearerSet: state.isBearerSet,
    token: state.token,
    setErrorCode: state.setErrorCode,
    setIsAuthenticated: state.setIsAuthenticated,
    setIsBearerSet: state.setIsBearerSet,
    setToken: state.setToken,
  }));

  const webAuth = new auth0.WebAuth({
    audience: env("REACT_APP_AUTH0_AUDIENCE"),
    domain: env("REACT_APP_AUTH0_DOMAIN"),
    clientID: env("REACT_APP_AUTH0_CLIENTID"),
    responseType: "token",
    redirectUri: `${env("REACT_APP_BASE_URL")}/auth`,
  });

  useEffect(() => {
    setIsAuthenticated(
      !!token // && isAfter(new Date(token?.expiresIn || ""), Date.now())
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [token]);

  const login = async (email: string, password: string) => {
    setErrorCode("");
    webAuth.login(
      {
        audience: env("REACT_APP_AUTH0_AUDIENCE"),
        realm: env("REACT_APP_AUTH0_REALM"),
        email,
        password,
      },
      (res) => {
        console.log("LOG RES", res);
        if (res?.error) {
          setErrorCode(ErrorCodes.AUTH_102);
        }
      }
    );
  };

  const logout = async () => {
    webAuth.logout({
      returnTo: `${env("REACT_APP_BASE_URL")}/login`,
      clientID: env("REACT_APP_AUTH0_CLIENTID"),
    });
  };

  return {
    isAuthenticated,
    isBearerSet,
    login,
    logout,
    token,
    setIsBearerSet,
    setToken,
    webAuth,
  };
};
