import { Box } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import React from "react";
import Banner, { BannerVariant } from "../Banner";
import Text, { TextVariant } from "../Text";

const useStyles = makeStyles((theme) => ({
  container: { marginBottom: "24px" },
  content: { alignItems: "center", display: "flex" },
  loginLink: { color: theme.palette.error.main },
}));

interface IErrorToast {
  className?: string;
  err?:
    | {
        code: number;
        data?: any;
      }
    | undefined;
  message?: any;
  showIcon?: boolean;
}

const ErrorToast = ({
  className,
  message = "",
  showIcon = false,
}: IErrorToast) => {
  const classes = useStyles();
  const errorMessage = <Text variant={TextVariant.medium}>{message}</Text>;

  return (
    <Banner
      className={`${classes.container} ${className}`}
      data-testid="error-banner"
      subtitle={
        <Box className={classes.content}>
          {showIcon && (
            <ErrorOutlineIcon fontSize="large" style={{ marginRight: 16 }} />
          )}
          {errorMessage}
        </Box>
      }
      type={BannerVariant.error}
    />
  );
};

export default ErrorToast;
