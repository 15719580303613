import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import React from "react";
import * as ReactDOM from "react-dom/client";
import { createBrowserHistory } from "history";
import App from "./App";
import AppTheme from "./AppTheme";
import ErrorBoundary from "./components/ErrorBoundary";
import * as serviceWorker from "./serviceWorker";
import { env } from "./utils/env";
import { Auth0Provider } from "@auth0/auth0-react";
import { BrowserRouter as Router } from "react-router-dom";
import { I18nextProvider } from "react-i18next";
import I18n from "./i18n";
import { QueryClient, QueryClientProvider } from "react-query";

export const history = createBrowserHistory();

Sentry.init({
  dsn: env("REACT_APP_SENTRY_DSN"),
  environment: env("REACT_APP_SENTRY_ENV"),
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
    }),
  ],
  tracesSampleRate: parseFloat(env("REACT_APP_SENTRY_TRACE_SAMPLE_RATE")),
});

const root = ReactDOM.createRoot(document.getElementById("root"));
const queryClient = new QueryClient();

root.render(
  <Sentry.ErrorBoundary
    fallback={(props) => <ErrorBoundary props={props} />}
    showDialog={true}
  >
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENTID}
      audience={process.env.REACT_APP_AUTH0_AUDIENCE}
      redirectUri={window.location.origin}
      onRedirectCallback={(res) => console.log("ON REDIRECT", res)}
      useRefreshTokens
      cacheLocation={"memory"}
    >
      <QueryClientProvider client={queryClient}>
        <AppTheme>
          <I18nextProvider i18n={I18n}>
            <Router>
              <App />
            </Router>
          </I18nextProvider>
        </AppTheme>
      </QueryClientProvider>
    </Auth0Provider>
  </Sentry.ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
