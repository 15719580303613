import React, { FC, useEffect } from "react";
import { ReactComponent as Illustration } from "../../../assets/illustrations/order_completed.svg";
import makeStyles from "@mui/styles/makeStyles";
import { AMP_EVENTS, BENJI_API_KEYS } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";
import Button, { ButtonVariant } from "../../../components/Button";
import {
  Box,
  CircularProgress,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import defaultTheme from "../../../styles/theme";
import { IBasicStepProps } from "../types";
import IllustrationLayout from "./illustrationLayout";
import { useQuery } from "react-query";
import { fetchBenjiCategories } from "../../../services/benji";
import { IBenjiCategory } from "../../../types";
import CategoryIcon from "../components/CategoryIcon";
import * as Sentry from "@sentry/react";

const approvedCategoryItemNames = new Set([
  "Bank fees",
  "Gear and equipment",
  "Legal and professional services",
  "Marketing and promotion",
  "Office expenses",
  "Professional development",
  "Software and apps",
]);

const summaryLoadingList = [
  {
    category_type: "",
    id: "",
    dominant_emoji_colour: "",
    emoji: "",
    key: "",
    name: "",
  },
  {
    category_type: "",
    id: "",
    dominant_emoji_colour: "",
    emoji: "",
    key: "",
    name: "",
  },
  {
    category_type: "",
    id: "",
    dominant_emoji_colour: "",
    emoji: "",
    key: "",
    name: "",
  },
  {
    category_type: "",
    id: "",
    dominant_emoji_colour: "",
    emoji: "",
    key: "",
    name: "",
  },
  {
    category_type: "",
    id: "",
    dominant_emoji_colour: "",
    emoji: "",
    key: "",
    name: "",
  },
];

const SummaryOverview: FC<IBasicStepProps> = ({
  animated,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  const { data, isLoading } = useQuery(
    BENJI_API_KEYS.BENJI_CATEGORIES,
    () => fetchBenjiCategories(),
    {
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
      onSuccess: () => {},
      select: (res) => res?.data,
    }
  );
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingSummaryOverview);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const categories = data?.categories;

  return (
    <animated.div style={springProps}>
      <IllustrationLayout
        imageAlt="illustration"
        imageHeightDesktop="297px"
        imageHeightMobile="225px"
        illustration={
          <Illustration style={{ height: "inherit", width: "inherit" }} />
        }
        subtitle={t("SUMMARY_OVERVIEW.SUBHEADING", { ns: "onboarding" })}
        title={t("SUMMARY_OVERVIEW.HEADING", { ns: "onboarding" })}
      >
        {isLoading ? (
          <Box className={classes.spinnerContainer}>
            <CircularProgress />
          </Box>
        ) : (
          <List className={classes.root}>
            {(categories || summaryLoadingList)
              ?.filter((item: IBenjiCategory) => {
                if (
                  (formik.values.businessMeal &&
                    item.name === "Business meals") ||
                  (formik.values.businessTravel &&
                    item.name === "Business travel") ||
                  (formik.values.cogs &&
                    item.name === "Materials and supplies (COGS)") ||
                  (!!formik.values.phoneUsage &&
                    item.name === "Mobile phone bill") ||
                  (formik.values.businessCommute.has("CAR") &&
                    (item.name === "Gas and fuel" ||
                      item.name === "Parking fees and tolls" ||
                      item.name === "Wash and road services")) ||
                  (formik.values.businessSize.has("EMPLOYEES") &&
                    item.name === "Employee salary and wages") ||
                  (formik.values.businessSize.has("SUBCONTRACTORS") &&
                    item.name === "Subcontractors") ||
                  (formik.values.workspace === "HOME_OFFICE" &&
                    (item.name === "Home furniture and appliances" ||
                      item.name === "Home repairs and maintenance" ||
                      item.name === "Home utilities")) ||
                  (formik.values.workspace === "OFFICE" &&
                    (item.name === "Office rent and lease" ||
                      item.name === "Office repairs and maintenance" ||
                      item.name === "Office utilities"))
                ) {
                  return true;
                }
                if (approvedCategoryItemNames.has(item.name)) {
                  return true;
                }
                return false;
              })
              ?.map((item: IBenjiCategory, index: number) => (
                <ListItem
                  key={`listItem_${item.id}_${index}`}
                  className={classes.item}
                >
                  {/* <ListItemAvatar>
                    <Avatar className={classes.avatar}>{item.}</Avatar>
                  </ListItemAvatar> */}
                  <CategoryIcon category={item} />
                  <ListItemText
                    classes={{ root: classes.label }}
                    primary={item.name}
                  />
                </ListItem>
              ))}
          </List>
        )}
        <Button
          className={classes.ctaBtn}
          disabled={isLoading}
          label={t("SUMMARY_OVERVIEW.PRIMARY_CTA", { ns: "onboarding" })}
          name="sounds-good"
          variant={ButtonVariant.contained}
          onClick={() => {
            try {
              trackAmpEvent(AMP_EVENTS.onboardingSummaryOverview);
            } catch (err) {
              console.error(err);
            }
            incrementStep();
          }}
        />
      </IllustrationLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  avatar: {
    backgroundColor: defaultTheme.colours.day050,
  },
  ctaBtn: {
    width: "100%",
  },
  item: {
    paddingLeft: 0,
  },
  label: {
    color: defaultTheme.colours.ink400,
    fontFamily: "NHaasGroteskDSPro55Rg",
    fontSize: "16px",
    fontWeight: 400,
    lineHeight: "24px",
  },
  root: {
    marginBottom: "24px",
    paddingTop: 0,
    "& li:first-child": {
      paddingTop: 0,
    },
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "30% 0",
  },
}));

export default SummaryOverview;
