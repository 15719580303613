import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box } from "@mui/material";
import { IBenjiCategory, ICategoryIconSize } from "../../../types";
import Text, { TextVariant } from "../../../components/Text";
import defaultTheme from "../../../styles/theme";

const CategoryIcon = ({
  category,
  size = ICategoryIconSize.small,
}: {
  category: IBenjiCategory;
  size?: ICategoryIconSize;
}) => {
  const classes = useStyles();

  return (
    <Box
      className={`${
        size === ICategoryIconSize.small
          ? classes.container
          : classes.containerLarge
      }`}
      style={{
        background: `linear-gradient(0deg, rgba(255, 255, 255, 0.84), rgba(255, 255, 255, 0.84)), ${category?.dominant_emoji_colour}`,
      }}
    >
      <Box
        className={
          size === ICategoryIconSize.small
            ? classes.transparency
            : classes.transparencyLarge
        }
      >
        <Text
          className={
            size === ICategoryIconSize.small
              ? classes.emoji
              : classes.emojiLarge
          }
          variant={TextVariant.medium}
        >
          {category?.emoji}
        </Text>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    borderRadius: defaultTheme.spacing[8],
    height: defaultTheme.spacing[8],
    marginRight: defaultTheme.spacing[3],
    width: defaultTheme.spacing[8],
  },
  containerLarge: {
    borderRadius: defaultTheme.spacing[9],
    height: defaultTheme.spacing[9],
    marginRight: defaultTheme.spacing[3],
    width: defaultTheme.spacing[9],
  },
  emoji: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
  },
  emojiLarge: {
    alignItems: "center",
    display: "flex",
    height: "100%",
    justifyContent: "center",
    fontSize: "24px",
  },
  transparency: {
    alignItems: "center",
    borderRadius: defaultTheme.spacing[8],
    flexDirection: "row",
    height: defaultTheme.spacing[8],
    justifyContent: "center",
    width: defaultTheme.spacing[8],
  },
  transparencyLarge: {
    alignItems: "center",
    borderRadius: defaultTheme.spacing[9],
    flexDirection: "row",
    height: defaultTheme.spacing[9],
    justifyContent: "center",
    width: defaultTheme.spacing[9],
  },
}));

export default CategoryIcon;
