import authENCA from "./translations/en/ca/auth.json";
import bankConnectionENCA from "./translations/en/ca/bank-connection.json";
import bannerENCA from "./translations/en/ca/banner.json";
import commonENCA from "./translations/en/ca/common.json";
import onboardingENCA from "./translations/en/ca/onboarding.json";
import passwordENCA from "./translations/en/ca/password.json";
import plansENCA from "./translations/en/ca/plans.json";
import authENUS from "./translations/en/ca/auth.json";
import bankConnectionENUS from "./translations/en/us/bank-connection.json";
import bannerENUS from "./translations/en/ca/banner.json";
import commonENUS from "./translations/en/us/common.json";
import onboardingENUS from "./translations/en/us/onboarding.json";
import passwordENUS from "./translations/en/us/password.json";
import plansENUS from "./translations/en/us/plans.json";

export const fallback = ["en-US", "en-CA"];
export const languages = ["en-US", "en-CA"];

export const resources = {
  "en-CA": {
    auth: authENCA,
    bankConnection: bankConnectionENCA,
    banner: bannerENCA,
    common: commonENCA,
    onboarding: onboardingENCA,
    password: passwordENCA,
    plans: plansENCA,
  },
  "en-US": {
    auth: authENUS,
    bankConnection: bankConnectionENUS,
    banner: bannerENUS,
    common: commonENUS,
    onboarding: onboardingENUS,
    password: passwordENUS,
    plans: plansENUS,
  },
};

export const defaultNamespace = "common";

export const namespaces = ["common", "onboarding"];
