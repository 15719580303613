import React, { FC, ReactNode, useState } from "react";
import { Box, Button } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import DoneIcon from "@mui/icons-material/Done";
import useTimeout from "../../hooks/useTimeout";
import Text, { TextVariant } from "../Text";
import defaultTheme from "../../styles/theme";

const useStyles = makeStyles((theme) => ({
  root: {
    border: `1px solid ${defaultTheme.colours.stone200}`,
    borderRadius: "4px",
    marginBottom: "8px",
    width: "100%",
    height: "56px",
    padding: "16px",
    color: defaultTheme.colours.ink300,
    transition: "none",

    "&:hover": {
      color: defaultTheme.colours.ink400,
      borderColor: defaultTheme.colours.stone400,
      backgroundColor: defaultTheme.colours.baseWhite,
    },
  },
  innerContainer: {
    alignItems: "center",
    display: "flex",
  },
  active: {
    backgroundColor: defaultTheme.colours.day050,
    border: `1px solid ${defaultTheme.colours.day400}`,
    borderRadius: "4px",
    color: defaultTheme.colours.night200,
    marginBottom: "8px",
    width: "100%",
    height: "56px",
    padding: "16px",
    transition: "none",

    "&:hover": {
      backgroundColor: defaultTheme.colours.day050,
    },
  },
  tickAnimation: {
    animation: `$tick 250ms 2 ${theme.transitions.easing.easeInOut}`,
  },
  label: {
    justifyContent: "space-between",
  },
  icon: {
    fill: defaultTheme.colours.night200,
    animation: `$show 500ms ${theme.transitions.easing.easeInOut}`,
  },
  startAdorn: {
    alignItems: "center",
    display: "flex",
    marginRight: "16px",
  },
  "@keyframes tick": {
    "0%": {
      opacity: 0.25,
    },
    "100%": {
      opacity: 1,
    },
  },
  "@keyframes show": {
    "0%": {
      opacity: 0,
    },
    "100%": {
      opacity: 1,
    },
  },
}));

interface IOption {
  className?: string;
  value: string | boolean;
  label: string;
  selected?: boolean;
  onClick?: (provinceData: any) => void;
  testId?: string;
  startAdornment?: ReactNode;
}

const Option: FC<IOption> = ({
  className,
  value,
  label,
  selected = false,
  onClick = () => null,
  testId,
  startAdornment,
}: any) => {
  const classes = useStyles();
  const [isPressed, setIsPressed] = useState(false);
  const optionStyle = selected ? classes.active : classes.root;
  const optionWithAnimation = `${classes.active} ${classes.tickAnimation}`;

  useTimeout(
    () => {
      setIsPressed(false);
      onClick(value);
    },
    isPressed ? 550 : null
  );

  return (
    <Button
      className={`${optionStyle} ${
        isPressed && optionWithAnimation
      } ${className}`}
      classes={{ text: classes.label }}
      onClick={() => {
        setIsPressed(true);
      }}
      data-testid={testId}
    >
      <Box className={classes.innerContainer}>
        {startAdornment ? (
          <Box className={classes.startAdorn}>{startAdornment}</Box>
        ) : null}
        <Text variant={TextVariant.medium}>{label}</Text>
      </Box>
      {(selected || isPressed) && <DoneIcon className={classes.icon} />}
    </Button>
  );
};

export default Option;
