import React, { FC, ReactNode } from "react";
import Navbar from "../Navbar";

const BasicNavbar: FC<IBasicNavbar> = ({
  externalLink,
  firstChild,
  secondChild,
}) => {
  return (
    <Navbar
      firstChild={firstChild}
      externalLink={externalLink || "https://www.betterwithbenji.com"}
      secondChild={secondChild}
    />
  );
};

interface IBasicNavbar {
  firstChild?: ReactNode;
  externalLink?: string;
  secondChild?: ReactNode;
}

export default BasicNavbar;
