import React, { FC } from "react";
import makeStyles from "@mui/styles/makeStyles";
import ProgressBarComponent from "../../../components/ProgressBar";
import Text, { TextVariant } from "../../../components/Text";
import defaultTheme from "../../../styles/theme";
import { Box } from "@mui/material";

const ProgressBar: FC<IProgressBar> = ({ currentStep = 0, totalSteps = 0 }) => {
  const classes = useStyles({});

  return (
    <Box className={classes.container}>
      <ProgressBarComponent
        className={classes.progressBar}
        currentStep={currentStep}
        totalSteps={totalSteps}
      />
      <Box className={classes.labels}>
        <Text className={classes.stepperText} variant={TextVariant.small}>
          {`${((currentStep / (totalSteps - 1)) * 100).toFixed(0)}%`}
        </Text>
        <Text className={classes.stepperText} variant={TextVariant.small}>
          {`${currentStep} of ${totalSteps - 2}`}
        </Text>
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: { position: "relative", width: "100%" },
  labels: {
    display: "flex",
    justifyContent: "space-between",
    flexDirection: "row",
    marginTop: "8px",
  },
  progressBar: {
    "& .MuiLinearProgress-bar": {
      backgroundColor: defaultTheme.colours.night200,
    },
    "& .MuiMobileStepper-progress": {
      backgroundColor: defaultTheme.colours.day200,
      borderRadius: "4px",
    },

    width: "100%",
  },
  stepperText: {
    color: defaultTheme.colours.ink200,
  },
}));

interface IProgressBar {
  currentStep?: number;
  totalSteps?: number;
}

export default ProgressBar;
