import { BankAccount, Business, Connection, Profile } from "./types";

export const transformBankAccounts = (raw: any) => {
  const account: BankAccount = {
    bankInstitutionId: raw?.bank_institution_id,
    id: raw?.id,
    isBusiness: raw?.is_business,
    mask: raw?.mask,
    name: raw?.name,
    plaidAccountId: raw?.plaid_account_id,
    plaidAccountIdStr: raw?.plaid_account_id_str,
  };

  return account;
};

export const transformBankConnections = (raw: any) => {
  const connections: Connection = {
    accounts: raw?.Accounts?.map(transformBankAccounts),
    institution: {
      accessToken: raw?.Institution?.access_token,
      businessId: raw?.Institution?.business_id,
      id: raw?.Institution?.id,
      plaidInstitutionId: raw?.Institution?.plaid_institution_id,
      plaidItemId: raw?.Institution?.plaid_item_id,
    },
  };

  return connections;
};

export const transformBenjiBusiness = (raw: any) => {
  const business: Business = {
    id: raw?.id,
    ownerId: raw?.owner_id,
    type: raw?.type,
    country: {
      id: raw?.country?.id,
      name: raw?.country?.name,
      country_code: raw?.country?.country_code,
    },
  };

  return business;
};

export const transformUserProfile = (raw: any) => {
  const profile: Profile = {
    id: raw?.id,
    email: raw?.email,
    firstName: raw?.first_name,
    lastName: raw?.last_name,
    country: {
      id: raw?.country?.id,
      name: raw?.country?.name,
      country_code: raw?.country?.country_code,
    },
    profile: {
      id: raw?.profile?.id,
      language: raw?.profile?.language,
      cron_timezone: raw?.profile?.cron_timezone,
      iana_timezone: raw?.profile?.iana_timezone,
      fcm_token: raw?.profile?.fcm_token,
    },
    settings: raw?.settings?.map((setting: any) => ({
      key: setting?.key,
      value: setting?.value,
    })),
  };
  return profile;
};
