export enum ErrorCodes {
  AUTH_101 = "AUTH-101",
  AUTH_102 = "AUTH-102",
  AUTH_103 = "AUTH-103",
  AUTH_104 = "AUTH-104",
  BANK_101 = "BANK-101",
  BANK_102 = "BANK-102",
  BANK_103 = "BANK-103",
  BANK_104 = "BANK-104",
  BANK_105 = "BANK-105",
  BANK_106 = "BANK-106",
  BANK_107 = "BANK-107",
  BANK_108 = "BANK-108",
  BANK_109 = "BANK-109",
  BANK_110 = "BANK-110",
  BANK_111 = "BANK-111",
  TAXP_101 = "TAXP-101",
  TAXP_102 = "TAXP-102",
}
