export enum BenjiCategoryType {
  BUSINESS_INCOME = "Business income",
  PERSONAL_INCOME = "Personal income",
  PERSONAL_INVESTMENTS = "Personal investments",
  PERSONAL_TRANSACTIONS = "Personal transactions",
  TAX_DEFERRED_CONTRIBUTIONS = "Tax-deferred contributions",
  TRANSFERS = "Transfers",
}

export enum CountryCode {
  CA = "CA",
  US = "US",
}

export type Region = CARegion | USRegion;

export enum CARegion {
  AB = "AB",
  BC = "BC",
  MB = "MB",
  NB = "NB",
  NL = "NL",
  NS = "NS",
  ON = "ON",
  PE = "PE",
  QC = "QC",
  SK = "SK",
}

export enum USRegion {
  AL = "AL",
  AK = "AK",
  AR = "AR",
  AZ = "AZ",
  CA = "CA",
  CO = "CO",
  CT = "CT",
  DC = "DC",
  DE = "DE",
  FL = "FL",
  GA = "GA",
  HI = "HI",
  IA = "IA",
  ID = "ID",
  IL = "IL",
  IN = "IN",
  KS = "KS",
  KY = "KY",
  LA = "LA",
  MA = "MA",
  MD = "MD",
  ME = "ME",
  MI = "MI",
  MN = "MN",
  MO = "MO",
  MS = "MS",
  MT = "MT",
  NC = "NC",
  ND = "ND",
  NE = "NE",
  NH = "NH",
  NJ = "NJ",
  NM = "NM",
  NV = "NV",
  NY = "NY",
  OH = "OH",
  OK = "OK",
  OR = "OR",
  PA = "PA",
  RI = "RI",
  SC = "SC",
  SD = "SD",
  TN = "TN",
  TX = "TX",
  UT = "UT",
  VT = "VT",
  VA = "VA",
  WA = "WA",
  WV = "WV",
  WI = "WI",
  WY = "WY",
}

export enum PlanType {
  BASIC = "BASIC",
  PRO = "PRO",
  UNLIMITED = "UNLIMITED",
}

export enum ProfileSettings {
  NOTIFICATIONS_ENABLED = "NOTIFICATIONS_ENABLED",
  ONBOARDING_STEP = "ONBOARDING_STEP",
}

export enum ProfileSettingOnboardingStep {
  ONBOARDING_STEP_BANK_ACCOUNT = "ONBOARDING_STEP_BANK_ACCOUNT",
  ONBOARDING_STEP_COMPLETED = "ONBOARDING_STEP_COMPLETED",
  ONBOARDING_STEP_COMPLETED_SIGNUP = "ONBOARDING_STEP_COMPLETED_SIGNUP",
  ONBOARDING_STEP_IMPORT = "ONBOARDING_STEP_IMPORT",
  ONBOARDING_STEP_NOTIFICATION = "ONBOARDING_STEP_NOTIFICATION",
}

export enum SupportedLanguages {
  ENCA = "en-CA",
  ENUS = "en-US",
}

export enum Routes {
  BANK_CONNECTIONS = "BANK_CONNECTIONS",
  BUSINESS_ACCOUNTS = "BUSINESS_ACCOUNTS",
  CHECK_EMAIL = "CHECK_EMAIL",
  CREATE_PASSWORD = "CREATE_PASSWORD",
  DASHBOARD = "DASHBOARD",
  HOME = "Home",
  LOADING = "Loading",
  LOGIN = "Login",
  MENU = "MENU",
  MESSAGES = "MESSAGES",
  MESSAGES_NAVIGATOR = "MESSAGES_NAVIGATOR",
  ONBOARDING = "Onboarding",
  ONBOARDING_ERROR = "ONBOARDING_ERROR",
  ONBOARDING_SUCCESS = "ONBOARDING_SUCCESS",
  RESET_PASSWORD = "RESET_PASSWORD",
  TAB_NAVIGATOR = "TAB_NAVIGATOR",
  TRANSACTION_CATEGORY = "TRANSACTION_CATEGORY",
  TRANSACTION_EDIT = "TRANSACTION_EDIT",
  TRANSACTIONS_LIST = "TRANSACTIONS_LIST",
  TRANSACTIONS_NAVIGATOR = "TRANSACTIONS_NAVIGATOR",
}
