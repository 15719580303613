export const BusinessCommuteOptions = [
  {
    emoji: "🚗",
    key: "CAR",
    label: "BUSINESS_COMMUTE.OPTION_CAR",
    value: "CAR",
  },
  {
    emoji: "🚌",
    key: "PUBLIC",
    label: "BUSINESS_COMMUTE.OPTION_PUBLIC_TRANSIT",
    value: "PUBLIC",
  },
  {
    emoji: "🚕",
    key: "TAXI_RIDESHARE",
    label: "BUSINESS_COMMUTE.OPTION_TAXI_RIDE_SHARE",
    value: "TAXI_RIDESHARE",
  },
  {
    emoji: "🚲",
    key: "BICYCLE",
    label: "BUSINESS_COMMUTE.OPTION_BICYCLE",
    value: "BICYCLE",
  },
  {
    emoji: "🚶",
    key: "WALK",
    label: "BUSINESS_COMMUTE.OPTION_WALK",
    value: "WALK",
  },
];

export const BusinessMealOptions = [
  { key: "YES", label: "BUSINESS_MEAL.OPTION_YES", value: true },
  { key: "NO", label: "BUSINESS_MEAL.OPTION_NO", value: false },
];

export const BusinessSizeOptions = [
  { key: "MYSELF", label: "BUSINESS_SIZE.OPTION_MYSELF", value: "MYSELF" },
  {
    key: "EMPLOYEES",
    label: "BUSINESS_SIZE.OPTION_EMPLOYEES",
    value: "EMPLOYEES",
  },
  {
    key: "SUBCONTRACTORS",
    label: "BUSINESS_SIZE.OPTION_SUBCONTRACTORS",
    value: "SUBCONTRACTORS",
  },
];

export const BusinessStartOptions = [
  {
    key: "CURRENT_YEAR",
    label: "BUSINESS_START.OPTION_START_CURRENT_YEAR",
    value: "CURRENT_YEAR",
  },
  {
    key: "LAST_YEAR",
    label: "BUSINESS_START.OPTION_START_LAST_YEAR",
    value: "LAST_YEAR",
  },
  {
    key: "PAST_YEARS",
    label: "BUSINESS_START.OPTION_START_PAST_YEARS",
    value: "PAST_YEARS",
  },
];

export const BusinessStructureOptions = [
  {
    key: "SOLE_PROPRIETORSHIP",
    label: "BUSINESS_STRUCTURE.SOLE_PROPRIETORSHIP",
    value: "SOLE_PROPRIETORSHIP",
  },
  {
    key: "COPRORATION_1",
    label: "BUSINESS_STRUCTURE.COPRORATION_1",
    value: "COPRORATION_1",
  },
  {
    key: "COPRORATION_2",
    label: "BUSINESS_STRUCTURE.COPRORATION_2",
    value: "COPRORATION_2",
  },
];

export const BusinessTravelOptions = [
  { key: "YES", label: "BUSINESS_TRAVEL.OPTION_YES", value: true },
  { key: "NO", label: "BUSINESS_TRAVEL.OPTION_NO", value: false },
];

export const COGSOptions = [
  { key: "YES", label: "COST_OF_GOODS_SOLD.OPTION_YES", value: true },
  { key: "NO", label: "COST_OF_GOODS_SOLD.OPTION_NO", value: false },
];

export const IncomeSourcesOptions = [
  {
    key: "SELF_EMPLOYMENT",
    label: "INCOME_SOURCES.OPTION_SELF_EMPLOYMENT",
    value: "SELF_EMPLOYMENT",
  },
  {
    key: "EMPLOYED",
    label: "INCOME_SOURCES.OPTION_EMPLOYED",
    value: "EMPLOYED",
  },
];

export const OperatingSystemOptions = [
  {
    emoji: "🍎",
    key: "IOS",
    label: "OPERATING_SYSTEM.OPTION_IOS",
    value: "IOS",
  },
  {
    emoji: "🤖",
    key: "ANDROID",
    label: "OPERATING_SYSTEM.OPTION_ANDROID",
    value: "ANDROID",
  },
];

export const SalesTaxOptions = [
  {
    key: "NO_ACCOUNT",
    label: "SALES_TAX.OPTION_NO_ACCOUNT",
    value: "NO_ACCOUNT",
  },
  {
    key: "ANNUALLY",
    label: "SALES_TAX.OPTION_ANNUALLY",
    value: "ANNUALLY",
  },
  {
    key: "QUARTERLY",
    label: "SALES_TAX.OPTION_QUARTERLY",
    value: "QUARTERLY",
  },
  { key: "MONTHLY", label: "SALES_TAX.OPTION_MONTHLY", value: "MONTHLY" },
];

export const SummaryOptions = [
  {
    key: "NO_ACCOUNT",
    label: "SALES_TAX.OPTION_NO_ACCOUNT",
    value: "NO_ACCOUNT",
  },
];

export const WorkspaceOptions = [
  {
    emoji: "🏠",
    key: "HOME_OFFICE",
    label: "WORKSPACE.OPTION_HOME_OFFICE",
    value: "HOME_OFFICE",
  },
  {
    emoji: "🏢",
    key: "OFFICE",
    label: "WORKSPACE.OPTION_OFFICE",
    value: "OFFICE",
  },
  {
    emoji: "🌴",
    key: "REMOTE",
    label: "WORKSPACE.OPTION_REMOTE",
    value: "REMOTE",
  },
];
