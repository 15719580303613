import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import Heading, { HeadingVariant } from "../../../components/Heading";
import useBreakpoint from "../../../hooks/useBreakpoint";
import makeStyles from "@mui/styles/makeStyles";
import defaultTheme from "../../../styles/theme";
import Text, { TextVariant } from "../../../components/Text";

const FormLayout: FC<IFormLayout> = ({
  children,
  subtitle,
  subtitleStyle,
  title,
}) => {
  const classes = useStyles({});
  const { isMobile } = useBreakpoint();

  return (
    <Box>
      <Heading
        className={classes.title}
        isBold
        variant={isMobile ? HeadingVariant.large : HeadingVariant.xlarge}
      >
        {title}
      </Heading>
      {subtitle ? (
        isMobile ? (
          <Text
            className={`${classes.subtitle} ${subtitleStyle}`}
            variant={TextVariant.small}
          >
            {subtitle}
          </Text>
        ) : (
          <Text
            className={`${classes.subtitle} ${subtitleStyle}`}
            variant={TextVariant.medium}
          >
            {subtitle}
          </Text>
        )
      ) : null}
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  title: {
    color: defaultTheme.colours.ink500,
    marginBottom: "12px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "8px",
    },
  },
  subtitle: {
    color: defaultTheme.colours.ink200,
    marginBottom: "24px",
  },
}));

interface IFormLayout {
  children?: ReactNode;
  subtitle?: string;
  subtitleStyle?: any;
  title: string;
}

export default FormLayout;
