import React from "react";
import {
  createTheme,
  responsiveFontSizes,
  StyledEngineProvider,
  Theme,
  ThemeProvider,
} from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";

declare module "@mui/styles/defaultTheme" {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const theme = createTheme({
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1024,
      xl: 1440,
    },
  },
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        input: {
          padding: "2.5px",
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
        },
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          borderRadius: "2px",
        },
      },
    },
    MuiFormControl: {
      defaultProps: {
        margin: "normal",
      },
      styleOverrides: {
        root: {
          display: "flex",
        },
      },
    },
    MuiFormControlLabel: {
      styleOverrides: {
        root: {
          fontWeight: 600,
          marginBottom: "0.25rem",
          lineHeight: "150%",
        },
      },
    },
    MuiFormHelperText: {
      styleOverrides: {
        root: {
          marginLeft: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: "#404040",
          fontWeight: 600,
          marginBottom: "0.25rem",
          lineHeight: "150%",
          display: "inline-block",
        },
      },
    },
    MuiInputBase: {
      defaultProps: {
        margin: "dense",
      },
      styleOverrides: {
        root: {
          padding: 0,
        },
      },
    },
    MuiInputLabel: {
      defaultProps: {
        disableAnimation: true,
        shrink: false,
      },
      styleOverrides: {
        formControl: {
          color: "#404040",
          fontWeight: 600,
          marginBottom: "0.25rem",
          position: "relative",
          transform: "none",
          lineHeight: "150%",
        },
        outlined: {
          transform: "none",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
          "&&:hover": {
            textDecoration: "underline",
          },
        },
      },
    },
    MuiOutlinedInput: {
      defaultProps: {
        notched: false,
      },
      styleOverrides: {
        input: {
          padding: "10.5px 14px",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
      },
      styleOverrides: {
        icon: {
          marginRight: "0.5rem",
        },
        iconOutlined: {
          right: 0,
        },
        select: {
          paddingTop: "0.75rem",
          paddingBottom: "0.75rem",
          paddingLeft: "1rem",
          lineHeight: "1.5rem",
          width: "100%",
          // maxWidth: "20rem",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        head: {
          fontWeight: 600,
        },
      },
    },
    MuiTextField: {
      defaultProps: { variant: "outlined" },
      styleOverrides: {},
    },
  },
  palette: {
    primary: {
      main: "#1858E8",
    },
    secondary: {
      main: "#C82010",
    },
    error: {
      main: "#C82010",
    },
    background: {
      default: "#fff",
    },
  },
  typography: {
    fontFamily: `NHaasGroteskTXPro55Rg`,
    fontSize: 14,
    fontWeightBold: 400,
    fontWeightMedium: 400,
    fontWeightRegular: 400,
    h1: {
      color: "#101010",
      fontSize: "32px",
      fontWeight: 300,
      lineHeight: 1.2,
    },
    h2: {
      fontSize: "24px",
      fontWeight: 500,
      lineHeight: 1.2,
    },
    h4: {
      fontSize: "20px",
      lineHeight: 1.4,
    },
    subtitle1: {
      color: "#606060",
    },
  },
  // spacing: 8,
});

const AppTheme = ({ children }: any) => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={responsiveFontSizes(theme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  </StyledEngineProvider>
);

export default AppTheme;
