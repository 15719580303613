import React from "react";
import { Box, Link } from "@mui/material";
import { makeStyles } from "@mui/styles";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import defaultTheme from "../../styles/theme";
import Text, { TextVariant } from "../Text";

const Footer = () => {
  const classes = useStyles();

  const renderMenuIcons = [
    {
      url: "https://instagram.com/betterwithbenji",
      children: <InstagramIcon className={classes.icon} />,
    },
    {
      url: "https://twitter.com/betterwithbenji",
      children: <TwitterIcon className={classes.icon} />,
    },
    {
      url: "https://www.linkedin.com/company/betterwithbenji",
      children: <LinkedInIcon className={classes.icon} />,
    },
  ];

  return (
    <Box className={`${classes.container} print-hide`}>
      <Box className={classes.leftInner}>
        <a
          className={classes.button}
          href="mailto:support@betterwithbenji.com?subject=Inquiry%20from%20web%20signup"
        >
          <Text isBold variant={TextVariant.medium}>
            Need help?
          </Text>
        </a>
      </Box>

      <Box>
        {renderMenuIcons?.map(({ children, url }, idx) => (
          <Link key={idx} href={url} className={classes.link} target="_blank">
            {children}
          </Link>
        ))}
      </Box>
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "64px",
    padding: "0 24px",
    width: "100%",
    boxShadow: `0px -1px 0px ${defaultTheme.colours.stone200}`,
    backgroundColor: defaultTheme.colours.baseWhite,
  },
  button: {
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "4px",
    color: defaultTheme.colours.ink200,
    cursor: "pointer",
    display: "flex",
    height: "40px",
    padding: "8px",
    textDecorationLine: "none",
    textTransform: "none",
    width: "fit-content",

    "&:hover": {
      backgroundColor: defaultTheme.colours.stone050,
      border: "none",
      color: defaultTheme.colours.ink300,
    },
  },
  leftInner: {
    alignItems: "center",
    display: "flex",
  },
  rowSocial: {
    display: "flex",
  },
  link: {
    marginRight: "12px",

    "&:last-child": {
      marginRight: "0px",
    },
  },
  icon: {
    fill: defaultTheme.colours.ink200,

    "&:hover": {
      fill: defaultTheme.colours.ink300,
    },
  },
  version: {
    color: defaultTheme.colours.ink100,
    marginLeft: "8px",
  },
}));

export default Footer;
