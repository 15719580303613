import React, { FC, ReactNode } from "react";
import { Box } from "@mui/material";
import Heading, { HeadingVariant } from "../../../components/Heading";
import useBreakpoint from "../../../hooks/useBreakpoint";
import makeStyles from "@mui/styles/makeStyles";
import defaultTheme from "../../../styles/theme";
import Text, { TextVariant } from "../../../components/Text";

const IllustrationLayout: FC<IIllustrationLayout> = ({
  children,
  illustration,
  imageHeightDesktop,
  imageHeightMobile,
  subtitle,
  title,
}) => {
  const classes = useStyles({
    desktopHeight: imageHeightDesktop,
    mobileHeight: imageHeightMobile,
  });
  const { isMobile } = useBreakpoint();

  return (
    <Box className={classes.container}>
      <Box className={isMobile ? classes.imageSmall : classes.imageLarge}>
        {illustration}
      </Box>
      <Heading
        className={classes.title}
        isBold
        variant={isMobile ? HeadingVariant.large : HeadingVariant.xlarge}
      >
        {title}
      </Heading>
      {subtitle ? (
        isMobile ? (
          <Text className={classes.subtitle} variant={TextVariant.small}>
            {subtitle}
          </Text>
        ) : (
          <Text className={classes.subtitle} variant={TextVariant.medium}>
            {subtitle}
          </Text>
        )
      ) : null}
      {children}
    </Box>
  );
};

const useStyles = makeStyles((theme) => ({
  container: {
    paddingTop: "24px",
  },
  imageSmall: {
    height: ({ mobileHeight }: IUseStyles) => mobileHeight || "225px",
    margin: "0 auto 24px auto",
    width: "327px",
  },
  imageLarge: {
    height: ({ desktopHeight }: IUseStyles) => desktopHeight || "297px",
    margin: "0 auto 24px auto",
    width: "432px",
  },
  title: {
    color: defaultTheme.colours.ink500,
    marginBottom: "12px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "8px",
    },
  },
  subtitle: {
    color: defaultTheme.colours.ink200,
    letterSpacing: "0.2px",
    marginBottom: "24px",
  },
}));

interface IUseStyles {
  desktopHeight?: string;
  mobileHeight?: string;
}

interface IIllustrationLayout {
  children?: ReactNode;
  illustration: ReactNode;
  imageAlt: string;
  imageHeightDesktop?: string;
  imageHeightMobile?: string;
  subtitle?: string;
  title: string;
}

export default IllustrationLayout;
