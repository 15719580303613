import create from "zustand";
import auth0 from "auth0-js";

export interface AuthStore {
  errorCode: string;
  isAuthenticated: boolean;
  isBearerSet: boolean;
  token: auth0.Auth0DecodedHash | null;
  reset: () => void;
  setErrorCode: (code: string) => void;
  setIsAuthenticated: (code: boolean) => void;
  setIsBearerSet: (value: boolean) => void;
  setToken: (trigger: auth0.Auth0DecodedHash | null) => void;
}

const initStore = {
  errorCode: "",
  isAuthenticated: false,
  isBearerSet: false,
  token: null,
};

export const useAuthStore = create<AuthStore>((set) => ({
  ...initStore,
  reset: () => set(() => ({ ...initStore })),
  setErrorCode: (errorCode: string) => set(() => ({ errorCode })),
  setIsAuthenticated: (isAuthenticated: boolean) =>
    set(() => ({ isAuthenticated })),
  setIsBearerSet: (isBearerSet: boolean) => set(() => ({ isBearerSet })),
  setToken: (token: auth0.Auth0DecodedHash | null) => set(() => ({ token })),
}));
