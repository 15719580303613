import React, { useEffect } from "react";
import Spinner from "../../components/Spinner";
import { useNavigate, useSearchParams } from "react-router-dom";
import { isPlanType } from "src/utils";
import { useMutation } from "react-query";
import * as Sentry from "@sentry/react";
import { fetchCheckoutInit } from "../../services/benji";

const CheckoutInit = () => {
  let [searchParams] = useSearchParams();
  const navigate = useNavigate();

  const userId = searchParams.get("userId");
  const planType = searchParams.get("planType");
  const taxYear = searchParams.get("taxYear");

  const generateCheckoutLink = useMutation(
    (args: any) => fetchCheckoutInit(args),
    {
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
    }
  );

  useEffect(() => {
    const getLink = async () => {
      const res = await generateCheckoutLink.mutateAsync({
        selection: planType,
        tax_year: Number(taxYear),
        user_id: userId,
      });
      console.log("res", res);
      if (res.session_url) {
        window.location.href = res.session_url;
        return;
      }
    };

    if (!isPlanType(planType || "") || !Number(taxYear) || !userId) {
      console.log(
        "invalid checkout init params",
        userId,
        planType,
        Number(taxYear)
      );
      navigate("/checkout/error");
      return;
    }

    if (userId && planType && taxYear) {
      console.log("calling getLink");
      getLink();
    } else {
      console.log(
        "missing checkout init params",
        userId,
        ":",
        planType,
        ":",
        taxYear
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId, planType, taxYear]);

  return <Spinner />;
};

export default CheckoutInit;
