import React from "react";
import { Box } from "@mui/material";
import Check from "@mui/icons-material/Check";
import Close from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import defaultTheme from "../../styles/theme";
import Text, { TextVariant } from "../Text";

const useStyles = makeStyles((theme) => ({
  root: {
    borderRadius: "4px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    marginBottom: "24px",
  },
  half: {
    width: "100%",
  },
  condition: {
    display: "flex",
    marginBottom: "8px",
  },
  invalid: {
    paddingLeft: "6px",
  },
  valid: {
    paddingLeft: "6px",
    textDecoration: "line-through",
  },
}));

const PasswordValidator = (props) => {
  const classes = useStyles();
  const { setIsValid, value } = props;

  const LOWER_UPPER_PATTEN = new RegExp("(?=.*[a-z])(?=.*[A-Z]).*$");
  const CHARACTER_TYPE_PATTERN = new RegExp(
    "^[a-zA-Z0-9!\"#\\$%&'\\(\\)\\*\\+,-\\.\\/:;<=>\\?@[\\]\\^_`\\{\\|}~]{12,}$"
  );
  const SPECIAL_PATTERN = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
  const NUMBER_PATTERN = /\d{1}/;

  const isValidCharacterTypeAndLength = CHARACTER_TYPE_PATTERN.test(value);
  const isContainsLowerAndUpperCase = LOWER_UPPER_PATTEN.test(value);
  const isContainsSpecial =
    NUMBER_PATTERN.test(value) && SPECIAL_PATTERN.test(value);

  setIsValid(
    isValidCharacterTypeAndLength &&
      isContainsSpecial &&
      isContainsLowerAndUpperCase
  );

  const generateCondition = ({ isValid, testid, text }) => {
    return (
      <Box className={classes.condition}>
        {isValid ? (
          <Check
            fontSize="small"
            style={{ color: defaultTheme.colours.night200 }}
            data-testid={`password-condition-${testid}-check`}
          />
        ) : (
          <Close
            fontSize="small"
            style={{ color: defaultTheme.colours.ink200 }}
            data-testid={`password-condition-${testid}-close`}
          />
        )}
        <Text
          className={isValid ? classes.valid : classes.invalid}
          variant={TextVariant.small}
        >
          {text}
        </Text>
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      {generateCondition({
        testid: "minimum",
        text: "12 characters minimum",
        isValid: isValidCharacterTypeAndLength,
      })}
      {generateCondition({
        testid: "ContainsLowerUpper",
        text: "Mix of upper and lowercase letters",
        isValid: isContainsLowerAndUpperCase,
      })}

      {generateCondition({
        testid: "lower",
        text: "At least one special character and one number",
        isValid: isContainsSpecial,
      })}
    </Box>
  );
};

export default PasswordValidator;
