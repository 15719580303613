import React, { FC, useEffect, useState } from "react";
import FormLayout from "../../../screens/Onboarding/steps/formLayout";

import { Box, FormGroup } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { AMP_EVENTS } from "../../../services/types";
import { useAnalytics } from "../../../services/analytics";

import ErrorToast from "../../../components/ErrorToast";

import PasswordValidator from "../../../components/PasswordValidator";

import ActionRedirect from "../../../screens/Onboarding/components/ActionRedirect";
import Text, { TextVariant } from "../../../components/Text";
import defaultTheme from "../../../styles/theme";
import Button, { ButtonVariant } from "../../../components/Button";
import Input from "../../../components/Input";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { IBasicStepProps } from "../types";
import { getErrorMessage } from "../errors";
import { Link } from "react-router-dom";

const CreateAccount: FC<IBasicStepProps> = ({
  animated,
  errorCode,
  formik,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const isCTADisabled =
    !formik.values.fullname ||
    !formik.values.email ||
    !formik.values.password ||
    !!formik.errors.fullname ||
    !!formik.errors.email ||
    !!formik.errors.password;

  const { trackGAPage } = useAnalytics();
  useEffect(() => {
    trackGAPage(AMP_EVENTS.onboardingCreateAccount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("CREATE_ACCOUNT.SUBHEADING", { ns: "onboarding" })}
        title={t("CREATE_ACCOUNT.HEADING", { ns: "onboarding" })}
      >
        {errorCode && (
          <ErrorToast
            message={t(getErrorMessage(errorCode), { ns: "onboarding" })}
          />
        )}

        <FormGroup>
          <Input
            autoComplete="name"
            className={classes.input}
            id="fullName"
            name="fullName"
            label={t("CREATE_ACCOUNT.FULL_NAME_LABEL", {
              ns: "onboarding",
            })}
            onBlur={formik.handleBlur}
            onChange={(e) => formik.setFieldValue("fullname", e.target.value)}
            isError={!!formik.errors.fullname}
            errorText={formik.errors.fullname}
            value={formik.values.fullname}
            tabIndex={1}
          />
          <Input
            autoComplete="email"
            className={classes.input}
            id="email"
            name="email"
            label={t("CREATE_ACCOUNT.EMAIL_LABEL", {
              ns: "onboarding",
            })}
            onBlur={formik.handleBlur}
            value={formik.values.email}
            onChange={(e) => formik.setFieldValue("email", e.target.value)}
            isError={!!(formik.errors.email && formik.errors.email)}
            errorText={formik.errors.email ? formik.errors.email : ""}
            type="email"
            tabIndex={2}
          />
          <Input
            className={classes.password}
            id="password"
            name="password"
            label="Password"
            value={formik.values.password}
            onBlur={formik.handleBlur}
            type={showPassword ? "text" : "password"}
            onChange={(e) => formik.setFieldValue("password", e.target.value)}
            isError={!!(formik.errors.password && formik.errors.password)}
            errorText={formik.errors.password ? formik.errors.password : ""}
            endAdornment={showPassword ? <VisibilityOff /> : <Visibility />}
            endAdornmentOnClick={() => setShowPassword(!showPassword)}
            tabIndex={3}
          />
          <PasswordValidator
            value={formik.values.password}
            setIsValid={setIsPasswordValid}
          />
        </FormGroup>

        <Box>
          <Text className={classes.disclaimerText} variant={TextVariant.small}>
            By clicking the "Create account" button, you agree to Benji's{" "}
            <a
              href="https://www.betterwithbenji.com/legal/terms"
              rel="noopener noreferrer"
              target="_blank"
              tabIndex={6}
              className={classes.disclaimerText}
            >
              Terms of Use
            </a>{" "}
            and{" "}
            <a
              href="https://www.betterwithbenji.com/legal/privacy"
              rel="noopener noreferrer"
              target="_blank"
              tabIndex={7}
              className={classes.disclaimerText}
            >
              Privacy Policy
            </a>
            .
          </Text>
        </Box>

        <ActionRedirect>
          <Button
            className={classes.fullWidth}
            disabled={formik.isSubmitting || !isPasswordValid || isCTADisabled}
            isLoading={formik.isSubmitting}
            label="Create account"
            name="create-account"
            type="submit"
            variant={ButtonVariant.contained}
            onClick={() => formik.handleSubmit()}
          />
        </ActionRedirect>
        <Box
          style={{
            display: "flex",
            paddingBottom: defaultTheme.spacing[6],
            paddingTop: defaultTheme.spacing[6],
          }}
        >
          <Text
            color={defaultTheme.colours.ink200}
            variant={TextVariant.medium}
          >
            {t("WELCOME.EXISTING_ACCOUNT", {
              ns: "onboarding",
            })}
          </Text>
          <Box style={{ width: "4px" }} />
          <Link className={classes.noUnderline} to={"/login"}>
            <Text variant={TextVariant.medium}>
              {t("LOGIN", {
                ns: "common",
              })}
            </Text>
          </Link>
        </Box>
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  disclaimerText: {
    color: defaultTheme.colours.ink200,
    marginBottom: "24px",
  },
  fullWidth: {
    width: "100%",
  },
  input: {
    marginBottom: "24px",
    marginTop: 0,
  },
  noUnderline: {
    textDecoration: "none",
  },
  password: {
    marginBottom: "12px",
    marginTop: 0,
  },
}));

export default CreateAccount;
