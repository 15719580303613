import React, { FC } from "react";
import SmallContainerBaseLayout from "../layouts/SmallContainerBaseLayout";
import makeStyles from "@mui/styles/makeStyles";
import { Box, useMediaQuery } from "@mui/material";
import { useTranslation } from "react-i18next";
import { ReactComponent as Illustration } from "../assets/illustrations/qr.svg";
import { ReactComponent as MobileIllustration } from "../assets/illustrations/success.svg";
import IllustrationLayout from "./Onboarding/steps/illustrationLayout";
import { useAuth } from "../services/auth";
import { useTheme } from "@mui/styles";
import Button, { ButtonVariant } from "../components/Button";
import defaultTheme from "../styles/theme";
import Text, { TextVariant } from "../components/Text";
import { useNavigate } from "react-router";

const Download: FC<IDownload> = () => {
  const classes = useStyles({});
  const { t } = useTranslation();
  const { isAuthenticated } = useAuth();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));
  const navigate = useNavigate();

  return (
    <>
      {isAuthenticated ? (
        <Box className={`${classes.footerContainer}`}>
          <Box className={classes.leftInner}>
            <a
              className={classes.emailButton}
              href="mailto:support@betterwithbenji.com?subject=Inquiry%20from%20web%20signup"
            >
              <Text isBold variant={TextVariant.medium}>
                Need help?
              </Text>
            </a>
          </Box>
          <Button
            label={t("LOG_OUT", { ns: "common" })}
            name="logout"
            onClick={() => {
              console.log("logout");
              navigate("/logout");
            }}
            variant={ButtonVariant.textNoUnderline}
          />
        </Box>
      ) : null}
      <SmallContainerBaseLayout footer={<></>} navbar={<></>}>
        <Box className={classes.container}>
          <IllustrationLayout
            imageAlt="illustration"
            imageHeightDesktop="297px"
            imageHeightMobile="225px"
            illustration={
              isMobile ? (
                <MobileIllustration
                  style={{ height: "inherit", width: "inherit" }}
                />
              ) : (
                <Illustration style={{ height: "inherit", width: "inherit" }} />
              )
            }
            subtitle={t("SUCCESS.SUBHEADING", { ns: "onboarding" }) || ""}
            title={t("SUCCESS.HEADING", { ns: "onboarding" })}
          >
            {isMobile ? (
              <a
                href={
                  "https://apps.apple.com/app/benji-find-tax-write-offs/id1584984281"
                }
              >
                <Button
                  className={classes.button}
                  label={t("SUCCESS.DOWNLOAD_APP", { ns: "onboarding" })}
                  name="cta"
                  variant={ButtonVariant.contained}
                />
              </a>
            ) : null}
          </IllustrationLayout>
        </Box>
      </SmallContainerBaseLayout>
    </>
  );
};

const useStyles = makeStyles((theme) => ({
  body: {
    color: defaultTheme.colours.ink100,
  },
  button: {
    marginTop: "24px",
    width: "100%",
  },
  container: {
    marginTop: "64px",
    padding: "24px 0",

    [theme.breakpoints.down("lg")]: {
      padding: "48px 24px 0 24px",
    },
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    marginTop: "40%",
  },
  footerContainer: {
    bottom: 0,
    position: "absolute",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: "64px",
    padding: "0 24px",
    width: "100%",
    boxShadow: `0px -1px 0px ${defaultTheme.colours.stone200}`,
    backgroundColor: defaultTheme.colours.baseWhite,
  },
  emailButton: {
    alignItems: "center",
    backgroundColor: "transparent",
    border: "none",
    borderRadius: "4px",
    color: defaultTheme.colours.ink200,
    cursor: "pointer",
    display: "flex",
    height: "40px",
    padding: "8px",
    textDecorationLine: "none",
    textTransform: "none",
    width: "fit-content",

    "&:hover": {
      backgroundColor: defaultTheme.colours.stone050,
      border: "none",
      color: defaultTheme.colours.ink300,
    },
  },
  leftInner: {
    alignItems: "center",
    display: "flex",
  },
}));

interface IDownload {}

export default Download;
