import React, { useEffect } from "react";
import { useAuthStore } from "../stores/auth";
import { useAppStore } from "../stores/app";
import { useBankConnectionStore } from "../stores/bankConnection";
import { useOnboardingStore } from "../stores/onboarding";
import { useAuth } from "../services/auth";
import Spinner from "../components/Spinner";

const Logout = () => {
  const { logout } = useAuth();
  const { reset: resetApp } = useAppStore();
  const { reset: resetAuth } = useAuthStore();
  const { reset: resetBank } = useBankConnectionStore();
  const { reset: resetOnboarding } = useOnboardingStore();

  useEffect(() => {
    const resetState = async () => {
      resetApp();
      resetBank();
      resetOnboarding();
      resetAuth();
      await logout();
    };
    resetState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <Spinner />;
};

export default Logout;
