import { Box } from "@mui/material";
import React, { FC, ReactNode } from "react";

interface ICustomSvgIcon {
  color?: string;
  className?: string;
  clickHandler?: () => void;
  children: ReactNode;
}

const CustomSvgIcon: FC<ICustomSvgIcon> = ({
  color,
  className,
  clickHandler,
  children,
}) => {
  return (
    <Box onClick={clickHandler} className={className} color={color}>
      {children}
    </Box>
  );
};

export default CustomSvgIcon;
