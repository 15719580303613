import { Box } from "@mui/material";
import { Switch, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import React, { FC } from "react";

const useStyles = makeStyles({
  wrapperSwitch: {
    display: "flex",
    alignItems: "center",
  },
  lableSwitch: {
    paddingRight: "100px",
  },
});

interface IToggleSwitch {
  isChecked: boolean;
  label?: string;
  onSwitch: () => void;
}

const ToggleSwitch: FC<IToggleSwitch> = ({ label, isChecked, onSwitch }) => {
  const { wrapperSwitch, lableSwitch } = useStyles();

  return (
    <Box className={wrapperSwitch}>
      {label && <Typography className={lableSwitch}>{label}</Typography>}
      <Switch onChange={onSwitch} checked={isChecked} />
    </Box>
  );
};

export default ToggleSwitch;
