import React, { useEffect, useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Box, Icon } from "@mui/material";
import { useFormik } from "formik";
import { Link, useNavigate } from "react-router-dom";
import defaultTheme from "../../styles/theme";
import Heading, { HeadingVariant } from "../../components/Heading";
import Text, { TextVariant } from "../../components/Text";
import Button, { ButtonVariant } from "../../components/Button";
import useBreakpoint from "../../hooks/useBreakpoint";
import SmallContainerBaseLayout from "../../layouts/SmallContainerBaseLayout";
import BasicNavbar from "../../components/Navbar/BasicNavbar";
import { useTranslation } from "react-i18next";
import {
  LOWER_UPPER_PATTERN,
  NUMBER_PATTERN,
  SPECIAL_PATTERN,
} from "../../utils/password";
import { useAuth } from "../../services/auth";
import Input from "../../components/Input";
import { ReactComponent as Illustration } from "../../assets/illustrations/welcome.svg";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import ErrorToast from "../../components/ErrorToast";
import { getErrorMessage } from "src/screens/Onboarding/errors";
import { useAuthStore } from "src/stores/auth";

interface IUseStyles {
  desktopHeight?: string;
  mobileHeight?: string;
}

const useStyles = makeStyles((theme) => ({
  container: {
    padding: "24px",
  },
  paper: {
    display: "flex",
    flexDirection: "column",
  },
  buttonGroup: {
    width: "100%",
  },
  imageSmall: {
    height: ({ mobileHeight }: IUseStyles) => mobileHeight || "225px",
    margin: "0 auto 24px auto",
    width: "327px",
  },
  imageLarge: {
    height: ({ desktopHeight }: IUseStyles) => desktopHeight || "297px",
    margin: "0 auto 24px auto",
    width: "432px",
  },
  input: {
    marginBottom: "24px",
    marginTop: 0,
  },
  link: {
    color: defaultTheme.colours.night200,
    "&:hover": {
      color: defaultTheme.colours.night300,
    },
    textDecoration: "none",
  },
  loginBtn: {
    marginRight: "12px",
    width: "100%",
  },
  title: {
    color: defaultTheme.colours.ink500,
    marginBottom: "12px",
    [theme.breakpoints.down("lg")]: {
      marginBottom: "8px",
    },
  },
  subtitle: {
    color: defaultTheme.colours.ink200,
    marginBottom: "24px",
  },
}));

const Login = (props: any) => {
  const classes = useStyles({
    desktopHeight: "297px",
    mobileHeight: "225px",
  });
  const { isMobile } = useBreakpoint();
  const { t } = useTranslation();
  const { isAuthenticated, login } = useAuth();
  const { errorCode } = useAuthStore((state) => ({
    errorCode: state.errorCode,
  }));
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) {
      navigate("/auth");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  const [contentHidden, setContentHidden] = useState(true);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    onSubmit: async (values, { setSubmitting }) => {
      await login(values.email, values.password);
      setSubmitting(false);
    },
    validate: (values) => {
      const errors: {
        email?: string;
        password?: string;
      } = {};

      if (values.email) {
        if (
          !values.email
            .toLowerCase()
            .match(
              /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        ) {
          errors.email =
            t("LOGIN_SCREEN.ERROR_EMAIL", {
              ns: "auth",
            }) || undefined;
        }
      }

      if (values.password) {
        const isInvalid =
          !LOWER_UPPER_PATTERN.test(values.password) ||
          !NUMBER_PATTERN.test(values.password) ||
          !SPECIAL_PATTERN.test(values.password) ||
          values?.password?.length < 12;
        if (isInvalid) {
          errors.password =
            t("LOGIN_SCREEN.ERROR_PASSWORD_HINT", {
              ns: "auth",
            }) || undefined;
        }
      }
      return errors;
    },
  });

  return (
    <SmallContainerBaseLayout navbar={<BasicNavbar />}>
      <Box className={classes.container}>
        <Box className={classes.paper}>
          {errorCode && (
            <ErrorToast
              message={t(getErrorMessage(errorCode), { ns: "auth" })}
            />
          )}
          <Box className={isMobile ? classes.imageSmall : classes.imageLarge}>
            <Illustration style={{ height: "inherit", width: "inherit" }} />
          </Box>
          <Heading
            className={classes.title}
            isBold
            variant={isMobile ? HeadingVariant.large : HeadingVariant.xlarge}
          >
            Welcome back
          </Heading>
          {isMobile ? (
            <Text className={classes.subtitle} variant={TextVariant.large}>
              Don't have an account?{" "}
              <Link className={classes.link} to="/signup" tabIndex={1}>
                Start for free
              </Link>
              .
            </Text>
          ) : (
            <Heading
              className={classes.subtitle}
              variant={HeadingVariant.medium}
            >
              Don't have an account?{" "}
              <Link className={classes.link} to="/signup" tabIndex={1}>
                Start for free
              </Link>
              .
            </Heading>
          )}
          <Input
            id="email"
            autoComplete="username"
            label="Email address"
            name="email"
            onChange={(e) => formik.setFieldValue("email", e.target.value)}
            type="email"
            tabIndex={2}
            className={classes.input}
            value={formik.values.email}
          />
          <Input
            id="password"
            autoComplete="current-password"
            endAdornment={
              contentHidden ? (
                <Icon
                  sx={{ color: defaultTheme.colours.ink100 }}
                  component={Visibility}
                />
              ) : (
                <Icon
                  sx={{ color: defaultTheme.colours.ink100 }}
                  component={VisibilityOff}
                />
              )
            }
            endAdornmentOnClick={() => {
              setContentHidden(!contentHidden);
            }}
            label="Password"
            name="password"
            type={contentHidden ? "password" : "text"}
            onChange={(e) => formik.setFieldValue("password", e.target.value)}
            tabIndex={2}
            className={classes.input}
            value={formik.values.password}
          />
          <Box className={classes.buttonGroup}>
            <Button
              className={classes.loginBtn}
              isLoading={formik.isSubmitting}
              label="Log in"
              name="login"
              onClick={formik.handleSubmit}
              variant={ButtonVariant.contained}
              tabIndex={5}
            />
          </Box>
        </Box>
      </Box>
    </SmallContainerBaseLayout>
  );
};

export default Login;
