import React from "react";
import { Box, CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    height: "100%",
  },
}));

const Spinner = () => {
  const classes = useStyles();
  return (
    <Box className={classes.container}>
      <CircularProgress />
    </Box>
  );
};

export default Spinner;
