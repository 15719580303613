import React, { FC, useEffect } from "react";
import FormLayout from "./formLayout";
import { useAnalytics } from "../../../services/analytics";
import { IBasicStepProps } from "../types";
import ActionRedirect from "../components/ActionRedirect";
import { makeStyles } from "@mui/styles";
import defaultTheme from "../../../styles/theme";
import { IncomeSourcesOptions } from "./config";
import Selection from "../../../components/Selection";
import { Box } from "@mui/material";
import { AMP_EVENTS, AMP_PROPERTIES } from "../../../services/types";

const IncomeSources: FC<IBasicStepProps> = ({
  animated,
  decrementStep,
  formik,
  incrementStep,
  springProps,
  t,
}) => {
  const classes = useStyles({});
  const { trackAmpEvent, trackGAPage } = useAnalytics();
  useEffect(() => {
    try {
      trackGAPage(AMP_EVENTS.onboardingIncomeSource);
    } catch (err) {
      console.error(err);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const selectionOptions = IncomeSourcesOptions?.map(
    ({ key, label, value }) => {
      const isActive = formik?.values?.incomeSources.has(value);
      return (
        <Selection
          key={key}
          className={classes.selectionItem}
          disabled={!isActive && formik?.values?.incomeSources?.size >= 3}
          isSelected={isActive}
          label={t(label, { ns: "onboarding" })}
          subtitle={t(`${label}_SUBTITLE`, { ns: "onboarding" })}
          onChange={() => {
            if (isActive) {
              if (formik.values.incomeSources?.delete(value)) {
                formik.setFieldValue(
                  "incomeSources",
                  formik.values.incomeSources
                );
              }
            } else {
              if (formik?.values?.incomeSources?.size < 3) {
                formik.setFieldValue(
                  "incomeSources",
                  formik.values.incomeSources?.add(value)
                );
              }
            }
          }}
          value={value}
        />
      );
    }
  );

  return (
    <animated.div style={springProps}>
      <FormLayout
        subtitle={t("INCOME_SOURCES.SUBHEADING", { ns: "onboarding" })}
        title={t("INCOME_SOURCES.HEADING", { ns: "onboarding" })}
      >
        {selectionOptions}
        <Box style={{ height: "16px" }} />
        <ActionRedirect
          isDisabled={
            formik?.values?.incomeSources.size === 0 ||
            !formik?.values?.incomeSources?.has("SELF_EMPLOYMENT")
          }
          goToPreviousStep={decrementStep}
          goToNextStep={() => {
            try {
              trackAmpEvent(AMP_EVENTS.onboardingIncomeSource, {
                [AMP_PROPERTIES.selections]: formik.values.incomeSources,
              });
            } catch (err) {
              console.error(err);
            }
            incrementStep();
          }}
          primaryLabel={t("INCOME_SOURCES.PRIMARY_CTA", {
            ns: "onboarding",
          })}
        />
      </FormLayout>
    </animated.div>
  );
};

const useStyles = makeStyles((theme) => ({
  closeIcon: {
    marginRight: "0",
  },
  label: {
    color: defaultTheme.colours.ink400,
  },
  selectionItem: {
    marginBottom: "8px",
  },
  spinnerContainer: {
    alignItems: "center",
    display: "flex",
    justifyContent: "center",
    margin: "30% 0",
  },
}));
export default IncomeSources;
