import create from "zustand";

export interface BankConnectionStore {
  errCode: string;
  triggerSuccess: boolean;
  reset: () => void;
  setErrCode: (code: string) => void;
  setTriggerSuccess: (trigger: boolean) => void;
}

const initStore = {
  errCode: "",
  triggerSuccess: false,
};

export const useBankConnectionStore = create<BankConnectionStore>((set) => ({
  ...initStore,
  reset: () => set(() => ({ ...initStore })),
  setErrCode: (errCode: string) => set(() => ({ errCode })),
  setTriggerSuccess: (triggerSuccess: boolean) =>
    set(() => ({ triggerSuccess })),
}));
