import React from "react";
import * as Sentry from "@sentry/react";
import { useAuth } from "../services/auth";
import { useQuery } from "react-query";
import { BENJI_API_KEYS, Business, Profile } from "../services/types";
import {
  fetchBankItems,
  fetchBusinesses,
  fetchUserProfile,
} from "../services/benji";
import { ProfileSettingOnboardingStep, ProfileSettings } from "../types/enums";
import {
  transformBenjiBusiness,
  transformUserProfile,
} from "../services/transformers";
import { useNavigate } from "react-router";
import Spinner from "../components/Spinner";

const Auth = () => {
  const { isAuthenticated, isBearerSet } = useAuth();
  const navigate = useNavigate();

  const { data: profile } = useQuery<any, any, Profile>(
    BENJI_API_KEYS.USER,
    () => fetchUserProfile(),
    {
      enabled: isAuthenticated && isBearerSet,
      onError: (err) => {
        navigate("/login");
      },
      select: transformUserProfile,
    }
  );
  const { data: businesses } = useQuery<any, any, Business[]>(
    BENJI_API_KEYS.BUSINESSES,
    () => fetchBusinesses(),
    {
      enabled: isAuthenticated && isBearerSet,
      onError: (err) => {
        console.error(err);
        Sentry.captureException(err);
      },
      select: (data) => data?.map(transformBenjiBusiness),
    }
  );

  useQuery(
    [BENJI_API_KEYS.BANK_CONNECTIONS],
    () => fetchBankItems(businesses?.[0]?.id || ""),
    {
      enabled: !!businesses && !!profile?.settings,
      onSuccess: (hasConnections) => {
        console.log("hasConnections", hasConnections);
        const onboardingSetting = profile?.settings?.find(
          (setting) => setting.key === ProfileSettings.ONBOARDING_STEP
        );
        if (
          hasConnections ||
          onboardingSetting?.value ===
            ProfileSettingOnboardingStep.ONBOARDING_STEP_COMPLETED
        ) {
          console.log("redirecting to success");
          navigate("/download");
          return;
        }
        if (
          onboardingSetting?.value ===
            ProfileSettingOnboardingStep.ONBOARDING_STEP_BANK_ACCOUNT ||
          onboardingSetting?.value ===
            ProfileSettingOnboardingStep.ONBOARDING_STEP_COMPLETED_SIGNUP
        ) {
          navigate("/onboarding/bank-connection");
          return;
        } else {
          console.log("other redirection");
          navigate("/signup");
          return;
        }
      },
      select: (data) => data?.length > 0,
    }
  );

  return <Spinner />;
};

export default Auth;
