import React, { FC, ReactNode } from "react";
import BaseLayout, { BaseLayoutContainerSize } from "./BaseLayout";

const SmallContainerBaseLayout: FC<ISmallContainerBaseLayout> = ({
  children,
  footer,
  navbar,
}) => {
  return (
    <BaseLayout
      containerSize={BaseLayoutContainerSize.small}
      footer={footer}
      navbar={navbar}
    >
      {children}
    </BaseLayout>
  );
};

interface ISmallContainerBaseLayout {
  children: ReactNode;
  footer?: ReactNode;
  navbar?: ReactNode;
}

export default SmallContainerBaseLayout;
