import React, { Component } from "react";
import * as Sentry from "@sentry/react";

class ErrorBoundary extends Component {
  componentDidMount() {
    Sentry.captureException(this.props.error);
  }

  //render fallback UI
  render() {
    return (
      <div>
        <h1>Uh oh. Something went wrong.</h1>
        <button
          onClick={() =>
            Sentry.showReportDialog({ eventId: this.props.eventId })
          }
        >
          Report feedback
        </button>
      </div>
    );
  }
}

export default ErrorBoundary;
